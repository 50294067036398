export const capitalize = (text?: string) =>
  text?.replace(/\b\w/g, (l) => l.toUpperCase()) || "";

export const createPathParams = (
  path: string,
  params: Record<string, string>
) => {
  const url = new URL(path, "http://www.example.com");
  Object.keys(params).forEach((key) => {
    url.searchParams.set(key, params[key]);
  });
  return url.pathname + url.search;
};
