import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import "./Button.scss";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface IGeneralProps {
  size?: "lg" | "md" | "sm";
  invert?: boolean;
  bold?: boolean;
  solid?: boolean;
  disabled?: boolean;
  to?: string;
  variant?: "outline" | "solid" | "none";
  loading?: boolean;
}

const Button: React.FC<(ButtonProps | LinkProps) & IGeneralProps> = ({
  to,
  className,
  invert,
  solid,
  bold,
  size,
  children,
  loading = false,
  ...props
}) => {
  const solidColor = solid ? "btn-solid" : "btn-outline";
  const invertedColor = invert ? "" : "btn-invert";
  const boldText = bold ? "btn-bold" : "";
  const btnSize =
    size === "sm" ? "btn-small" : size === "lg" ? "btn-large" : "btn-medium";

  // "to" means this is not a button, but an anchor tag (link)
  if (to) {
    return (
      <Link
        to={to}
        className={`btn btn-link ${solidColor} ${invertedColor} ${boldText} ${className} ${btnSize}`}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={loading}
      className={`btn btn-component ${solidColor} ${invertedColor} ${boldText} ${className} ${btnSize} ${
        loading ? "btn-disabled" : ""
      }`}
      {...(props as ButtonProps)}
    >
      {loading && (
        <>
          <FontAwesomeIcon icon={faSpinner} spin />
          &nbsp;
        </>
      )}
      {children}
    </button>
  );
};

export default Button;
