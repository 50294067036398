import "./ProjectRow.scss";

type Props = {
  content: IProject & { team: ITeam["name"]; creator: IUser["username"] };
  onRowClicked: (data: any) => any;
};
// "Name",
// "Description",
// "Team",
// "Creator",
// "Most recent version
const ProjectRow = ({ content: project, onRowClicked }: Props) => {
  return (
    <tr className="project-item" onClick={(e) => onRowClicked(project._id)}>
      <th className="project-item__name">{project.name}</th>
      <th className="project-item__description" colSpan={2}>
        {project.description.slice(0, 50) + "..."}
      </th>
      <th className="project-item__team">{project.team}</th>
      <th className="project-item__creator">{project.creator}</th>
    </tr>
  );
};

export default ProjectRow;
