import { faFloppyDisk, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import TextField from "components/TextField/TextField";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import "./VersionRow.scss";

type Props<TAction> = {
  content: Required<IVersion>;
  onRowClicked: (data: TAction) => any;
};

type TVersionAction = {
  versionId: string;
  action: "delete" | "saveName";
  data?: string;
};

const VersionRow = ({
  content: version,
  onRowClicked,
}: Props<TVersionAction>) => {
  const versionNameRef = useRef<HTMLInputElement>(null);

  const [nameFieldClicked, setNameFieldClicked] = useState<boolean>(false);
  const [unsaved, setUnsaved] = useState<boolean>(false);
  const formattedDate = version?.createdAt
    ? format(new Date(version?.createdAt), "MMMM dd, yyyy")
    : "";

  useEffect(() => {
    if (nameFieldClicked) {
      versionNameRef.current?.focus();
      console.log("versionNameRef:", versionNameRef.current?.name);
    }
  }, [nameFieldClicked]);

  const onSaveNameClick = () => {
    if (
      versionNameRef.current?.value &&
      versionNameRef.current?.value.length > 0
    ) {
      console.log("onSaveNameClick called and ref is not empty");
      setNameFieldClicked(false);
      onRowClicked({
        action: "saveName",
        versionId: version._id,
        data: versionNameRef.current.value,
      });
      setUnsaved(false);
    } else {
      versionNameRef.current?.checkValidity();
    }
  };

  return (
    <tr className="version-item">
      <td
        className="version-item__name"
        onClick={() => {
          setNameFieldClicked(true);
        }}
      >
        <TextField
          nolabel
          className={nameFieldClicked ? "" : "version-item__name-field"}
          ref={versionNameRef}
          name={version.name}
          defaultValue={version.name}
          readOnly={!nameFieldClicked}
          onChange={() => setUnsaved(true)}
          onBlur={() => {
            setNameFieldClicked(false);
          }}
          required
          minLength={6}
        />
      </td>
      <td className="version-item__date">{formattedDate}</td>
      <td className="version-item__tickets-total">{version.tickets.length}</td>
      <td className="version-item__controls">
        <span>
          <Button
            onClick={onSaveNameClick}
            invert
            title="Save New Name"
            style={{
              visibility: nameFieldClicked || unsaved ? "visible" : "hidden",
            }}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </Button>

          <Button
            onClick={() =>
              onRowClicked({ action: "delete", versionId: version._id })
            }
            invert
            title="Delete this version"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </span>
      </td>
    </tr>
  );
};

export default VersionRow;
