import Button from "components/Button/Button";
import TextField from "components/TextField/TextField";
import { useRegisterMutation } from "features/auth/authApi.slice";
import Loader from "layouts/Loaders/Loader";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";
import "./Register.scss";
import PublicContainer from "layouts/PublicPage/PublicPage";

type Props = {};
type TRegHandler = React.FormEventHandler<HTMLFormElement>;

const Register = (props: Props) => {
  const navigate = useNavigate();
  const [isPassMismatch, setIsPassMismatch] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [register, { isError, isLoading: isRegisterLoading }] =
    useRegisterMutation();
  const formRef = useRef<HTMLFormElement>(null);

  const onRegistrationSubmit: TRegHandler = async (e) => {
    e.preventDefault();
    const userForm = getFormData<TUserRegister>(e.currentTarget);
    if (userForm["password"] !== userForm["repeat"]) {
      setIsPassMismatch(true);
      setErrMsg("Passwords don't match");
      return;
    }
    setIsPassMismatch(false);
    setErrMsg("");
    const userObject = await register(userForm)
      .unwrap()
      .then((response) => {
        toast.success(response?.message);
        return response;
      })
      .catch(
        mutationFailHandler("User", (msg, error) => {
          toast.error(msg);
          console.log("error :>> ", error);
        })
      );
    if (userObject) {
      navigate(`/membership/${userObject.data._id}`, { replace: true });
    }
  };

  if (isRegisterLoading) return <Loader />;
  return (
    <PublicContainer
      title="Registration"
      description="
        Regular users can create and get tickets assigned. Managers can manage
        projects and teams. If you create an organization then you become a Director, 
        which means you have all permissions stated before, plus you can accept users 
        and assign roles to them."
    >
      <form
        ref={formRef}
        className="register-form"
        onSubmit={onRegistrationSubmit}
      >
        <TextField name="username" required minLength={6} />
        <TextField name="password" type="password" required minLength={10} />
        <TextField name="repeat" type="password" required />
        {(isError || isPassMismatch) && (
          <p className="register-form__error">{errMsg}</p>
        )}
        <div className="register-form__controls">
          <Button size="sm" bold type="submit">
            Submit
          </Button>
          <Button size="sm" to={"/login"} invert>
            Login
          </Button>
          <Button size="sm" to={"/"} invert>
            Welcome page
          </Button>
        </div>
      </form>
    </PublicContainer>
  );
};

export default Register;
