import { faFloppyDisk, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import {
  useDeleteProjectByIdMutation,
  useGetProjectByIdQuery,
  useUpdateProjectByIdMutation,
} from "features/projects/projectApi.slice";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EditProjectFormElements } from "../Forms/EditProjectElements";
import { mutationFailHandler } from "utils/requests";
import { getFormData } from "utils/forms";
import { toast } from "react-toastify";

type Props = {
  className: string;
  project: IProject;
  user: IUser;
  teams: ITeam[];
  updateProject: ReturnType<typeof useUpdateProjectByIdMutation>[0];
  deleteProject: ReturnType<typeof useDeleteProjectByIdMutation>[0];
  refetch: ReturnType<typeof useGetProjectByIdQuery>["refetch"];
};

const ProjectEdit = ({
  className,
  project,
  user,
  teams,
  updateProject,
  deleteProject,
  refetch,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const onUpdateProjectClick = async () => {
    if (!formRef.current) return;
    const projectUpdate = getFormData<IProjectUpdate["update"]>(
      formRef.current
    );
    await updateProject({
      id: project._id,
      update: projectUpdate,
    })
      .unwrap()
      .then((response) => {
        toast.info("Project update success!");
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("error :>> ", error);
        toast.error("Project update failed!");
        mutationFailHandler("Project")(error);
      });
    refetch();
  };

  const onDeleteProjectClick = async () => {
    await deleteProject(project._id)
      .unwrap()
      .then((response) => {
        toast.warn("Project has been deleted");
        console.log(response);
        return response;
      })
      .catch((error) => {
        toast.error(`Project deletion error:${error.data.message}`);
        mutationFailHandler("Project")(error);
      });
    navigate("/projects/", { replace: true });
  };

  const controls = (
    <>
      <Button
        type="submit"
        size="sm"
        invert
        bold
        onClick={onUpdateProjectClick}
      >
        <FontAwesomeIcon icon={faFloppyDisk} />
        &nbsp;Save project changes
      </Button>
      <Button
        type="submit"
        size="sm"
        invert
        bold
        onClick={onDeleteProjectClick}
      >
        <FontAwesomeIcon icon={faTrash} />
        &nbsp;Delete project
      </Button>
    </>
  );

  return (
    <form ref={formRef} className={className}>
      <Card
        title="Edit Project:"
        data={project}
        elements={EditProjectFormElements(user, teams)}
        controls={controls}
      />
    </form>
  );
};

export default ProjectEdit;
