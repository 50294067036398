import {
  faGraduationCap,
  faHatWizard,
  faHelmetSafety,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiSlice } from "app/api/api.slice";
import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import Notification from "components/Notification/Notification";
import TextField from "components/TextField/TextField";
import { setCredentials } from "features/auth/auth.slice";
import { useLoginMutation, useRefreshQuery } from "features/auth/authApi.slice";
import { useQuote } from "hooks/useQuote";
import Loader from "layouts/Loaders/Loader";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { demoCredentials } from "utils/demo";
import "./Login.scss";

type Props = {};

const Login = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dailyQuote = useQuote();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");
  const [membershipRequestUrl, setMembershipRequestUrl] = useState<
    string | null
  >(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const errRef = useRef<HTMLParagraphElement>(null);
  const [login, { isLoading: isLoginLoading, isError }] = useLoginMutation();

  const {
    isSuccess: isRefreshSuccess,
    isLoading: isRefreshLoading,
    isFetching: isRefreshFetching,
    data: refreshAuthData,
  } = useRefreshQuery();

  const from = location.state?.from?.pathname || "/dashboard";

  const loginFailHandler = (error: any) => {
    const status = error?.originalStatus || error?.status;
    let msg: string;
    switch (status) {
      case 400:
        msg = "Missing Username or Password";
        break;
      case 401:
        msg = "Incorrect credentials";
        break;
      case 406:
        msg = "User doesn't belong to an organization";
        setMembershipRequestUrl(`/membership/${error.data.uid}`);
        break;
      case 500:
        msg = "Internal Server Error";
        break;
      default:
        msg = "No server response";
        break;
    }
    console.log("error :>> ", error);
    setErrMsg(msg);
    toast.error(error.data.error ?? msg);

    errRef.current?.focus();
  };

  const loginHandler = async (credentials: IAuthLogin) => {
    return await login(credentials)
      .unwrap()
      .then((response) => {
        dispatch(setCredentials(response));
        toast.success("Sign-in success!");
        navigate(from, { replace: true });
        return response;
      })
      .catch((e) => {
        formRef.current?.reset();
        loginFailHandler(e);
      });
  };

  const onLoginSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    if (!username || !password) {
      setErrMsg("Please write your credentials in the fields above");
      return;
    }
    dispatch(apiSlice.util.resetApiState());
    await loginHandler({ username, password });
    if (membershipRequestUrl) navigate(membershipRequestUrl, { replace: true });
  };

  const onDemoClick =
    (demoUserType: TDemoType): React.MouseEventHandler<HTMLButtonElement> =>
    async (e) => {
      e.preventDefault();
      dispatch(apiSlice.util.resetApiState());
      await loginHandler(demoCredentials[demoUserType]);
      if (membershipRequestUrl)
        navigate(membershipRequestUrl, { replace: true });
    };

  const onChangeCredential: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    isFormEmpty() && setErrMsg("");

  useEffect(() => {
    usernameRef.current?.focus();
  }, [usernameRef.current?.value]);

  const isFormEmpty = () =>
    usernameRef.current?.value.length === 0 &&
    passwordRef.current?.value.length === 0;

  useEffect(() => {
    if (membershipRequestUrl) {
      navigate(membershipRequestUrl, {
        replace: true,
      });
    }
  }, [membershipRequestUrl, navigate]);

  // Used to redirect the user if already logged in,
  // since no credentials are stored in the browser except the server cookies
  useEffect(() => {
    if (isRefreshSuccess) {
      dispatch(setCredentials(refreshAuthData));
      navigate(`${from === "/login" ? "/dashboard" : from}`, {
        state: { from: location },
        replace: true,
      });
    }
  }, [isRefreshSuccess, dispatch, location, from, navigate, refreshAuthData]);

  if ((isLoginLoading || isRefreshLoading || isRefreshFetching) && !isError)
    return <Loader />;
  // if (isError) return <Error />;

  return (
    <div className="login">
      <div className="login-side">
        <form ref={formRef} className="login-form">
          <h1>Login</h1>
          <TextField
            onChange={onChangeCredential}
            ref={usernameRef}
            name="username"
          />
          <TextField
            onChange={onChangeCredential}
            ref={passwordRef}
            name="password"
            type="password"
          />
          {isError && isFormEmpty() && (
            <p ref={errRef} className="login-form__error">
              {errMsg}
            </p>
          )}
          <FlexCenter className="login-form__submit">
            <FlexCenter>
              <Button type="submit" onClick={onLoginSubmit}>
                Confirm
              </Button>
              <Link className="login-form__register-link" to={"/register"}>
                Or create an account...
              </Link>
            </FlexCenter>
            <hr className="login-form__separator" />
            <span>Demo options:</span>
            <div className="login-form__submit-options">
              <FlexCenter>
                <Button type="submit" onClick={onDemoClick("director")}>
                  <FontAwesomeIcon icon={faHatWizard} />
                  &nbsp;Director
                </Button>
                <Button type="submit" onClick={onDemoClick("manager")}>
                  <FontAwesomeIcon icon={faHelmetSafety} />
                  &nbsp;Manager
                </Button>
              </FlexCenter>
              <FlexCenter>
                <Button type="submit" onClick={onDemoClick("regular")}>
                  <FontAwesomeIcon icon={faGraduationCap} />
                  &nbsp;Regular
                </Button>
                <Button type="submit" onClick={onDemoClick("disabled")}>
                  <FontAwesomeIcon icon={faUserSlash} />
                  &nbsp;Disabled
                </Button>
              </FlexCenter>
            </div>
            <div className="login-form__demo-legend">
              To test the different levels of permissions, click on each role,
              with Director being the highest level and regular the lowest.
            </div>
          </FlexCenter>
        </form>
      </div>
      {dailyQuote && (
        <div className="login-quote">
          <Notification>
            <h1 style={{ fontSize: "var(--font-size-xxl)" }}>
              &quot;{dailyQuote.text}&quot;
            </h1>
            <h3
              style={{ fontSize: "var(--font-size-lg)", fontWeight: "bolder" }}
            >
              {dailyQuote.author}
            </h3>
          </Notification>
        </div>
      )}
    </div>
  );
};

export default Login;
