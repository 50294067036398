import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import {
  useCreateVersionByProjectIdMutation,
  useGetProjectByIdQuery,
} from "features/projects/projectApi.slice";
import { useRef } from "react";
import { AddVersionFormElements } from "../Forms/AddVersionElements";

type Props = {
  projectId: string;
  className?: string;
  createVersion: ReturnType<typeof useCreateVersionByProjectIdMutation>[0];
  refetch: ReturnType<typeof useGetProjectByIdQuery>["refetch"];
};

const AddVersion = ({
  projectId,
  className,
  createVersion,
  refetch,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const onAddVersionSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newVersionName = formData.get("name");
    const newTeamUsers: IVersionCreate = {
      id: projectId,
      update: newVersionName
        ? {
            version: { name: newVersionName as string },
          }
        : undefined,
    };
    try {
      const teamObject = await createVersion(newTeamUsers).unwrap();
      console.log(teamObject);
      formRef.current?.reset();
      refetch();
    } catch (error: any) {
      let errMsg = "";
      const status = error?.originalStatus || error?.status;
      switch (status) {
        case 400:
          errMsg = "Incorrect data provided";
          break;
        case 409:
          errMsg = "Version name already in use";
          break;
        default:
          errMsg = "Unexpected error";
          break;
      }
      console.log(errMsg);
    }
  };
  const controls = (
    <Button size="sm" invert bold>
      <FontAwesomeIcon icon={faTag} /> &nbsp; Add new version
    </Button>
  );
  return (
    <form ref={formRef} className={className} onSubmit={onAddVersionSubmit}>
      <Card
        data={{}}
        title="Add new version:"
        elements={AddVersionFormElements}
        controls={controls}
      />
    </form>
  );
};

export default AddVersion;
