import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "components/TextField/TextField";
import { useCallback, useRef, useState } from "react";
import Button from "../Button/Button";
import "./Table.scss";

type TTagInfo = { text: string; span: number };
type Props = {
  loading?: boolean;
  className?: string;
  headerTags: (string | TTagInfo)[];
  pageEntries?: number;
  data?: any[];
  nocaption?: boolean;
  caption: string;
  Row: (content: any, onRowClicked: (data: any) => void) => JSX.Element;
  onClicked?: (data: any) => void;
  extraControls?: JSX.Element;
};

const Table = function ({
  extraControls,
  nocaption = false,
  loading = false,
  className = "",
  headerTags,
  pageEntries = 5,
  data,
  caption,
  Row,
  onClicked,
}: Props) {
  const rowsRef = useRef<HTMLInputElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(pageEntries ?? 5);

  const limits = useCallback(() => {
    const total = data?.length ?? 0;
    const maxPages = Math.ceil(total / entriesPerPage);
    const start = (currentPage - 1) * entriesPerPage + 1;
    const end = maxPages === currentPage ? total : start + entriesPerPage - 1;

    return {
      start,
      end,
      total,
      maxPages,
    };
  }, [data, entriesPerPage, currentPage]);

  const onClickPrevButton = () =>
    setCurrentPage((page) => (!page ? 1 : page - 1));
  const onClickNextButton = () =>
    currentPage < limits().maxPages && setCurrentPage((page) => page + 1);

  const onRowsSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setEntriesPerPage(parseInt(rowsRef.current?.value ?? "5"));
  };
  const onRowsSetClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setEntriesPerPage(parseInt(rowsRef.current?.value ?? "5"));
  };

  const headersInfo: TTagInfo[] = headerTags.map((tag) => ({
    text: (tag as TTagInfo)?.text ?? tag,
    span: (tag as TTagInfo)?.span ?? 1,
  }));
  const rowSpan = headersInfo.reduce((acc, info) => acc + info.span, 0);

  const MemoizedRows =
    data && data.length
      ? data
          .slice(limits().start - 1, limits().end)
          .map((row) => (
            <Row key={row._id} content={row} onRowClicked={onClicked} />
          ))
      : null;

  return (
    <div className={`table-container  ${className}`}>
      {nocaption ? null : <div className="table__caption">{caption}</div>}
      <div className="table-scroll__container">
        <table className="table">
          <thead className="table__header">
            <tr className="table__header-row">
              <th colSpan={rowSpan}>
                <form onSubmit={onRowsSubmit}>
                  <span className="table__header-row-entries">
                    <Button size="sm" onClick={onRowsSetClick}>
                      Set size
                    </Button>
                    <TextField
                      ref={rowsRef}
                      txtSize={"sm"}
                      name="rows"
                      type="number"
                      nolabel
                      defaultValue={entriesPerPage}
                      className="table__header-row-input"
                      min={1}
                      max={10}
                      required
                      pattern="/d|10"
                    />
                    Rows
                  </span>
                </form>
              </th>
            </tr>
            <tr className="table__header-row">
              {headersInfo.map(({ text, span }) => (
                <th key={text} colSpan={span} className="table__header-tag">
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {!data || !data?.length || loading ? (
              <tr>
                <th colSpan={rowSpan}>
                  {loading ? (
                    <span>
                      Loading...
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </span>
                  ) : (
                    "No entries have been made yet"
                  )}
                </th>
              </tr>
            ) : (
              MemoizedRows
            )}
          </tbody>
        </table>
      </div>

      <div className={`table__footer ${!extraControls ? "table__bottom" : ""}`}>
        {!data || !data.length ? null : (
          <>
            <div className="footer__left">
              Showing {limits().start} to {limits().end} of {limits().total}{" "}
              entries
            </div>
            {limits().maxPages === 1 ? null : (
              <div className="footer__right">
                <Button
                  invert
                  bold
                  size="sm"
                  onClick={onClickPrevButton}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>{currentPage}</span>
                <Button
                  invert
                  bold
                  size="sm"
                  onClick={onClickNextButton}
                  disabled={currentPage === limits().maxPages}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`${
          extraControls ? "table__extra-controls table__bottom" : ""
        }`}
      >
        {extraControls}
      </div>
    </div>
  );
};

export default Table;
