import Table from "components/Table/Table";
import { useGetTicketsQuery } from "features/tickets/ticketApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import TicketRow from "pages/Tickets/Rows/TicketRow";
import "./TicketsList.scss";

type Props = {};

const Tickets = (props: Props) => {
  const {
    data: tickets,
    isLoading,
    isFetching,
    isError,
  } = useGetTicketsQuery();

  if (isLoading || isFetching) return <Loader />;
  if (isError) return <Error />;
  return (
    <PageContainer>
      <div className="tickets__container">
        <Table
          caption="My tickets"
          data={tickets}
          headerTags={[
            "Name",
            "Project",
            "Assignee",
            "Status",
            "Priority",
            "Severity",
            "Version",
            "Controls",
          ]}
          Row={TicketRow}
        />
      </div>
    </PageContainer>
  );
};

export default Tickets;
