import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useGetProjectByIdQuery } from "features/projects/projectApi.slice";
import { useGetUserByIdQuery } from "features/users/userApi.slice";
import {
  getTicketPriority,
  getTicketSeverity,
  getTicketStatus,
} from "utils/ticket/settings";

export const TicketDataElements = (ticket: ITicket) => {
  const { data: creator } = useGetUserByIdQuery(ticket?.creatorId || skipToken);
  const { data: assignee } = useGetUserByIdQuery(
    ticket?.assigneeId || skipToken
  );
  const { data: project } = useGetProjectByIdQuery(
    ticket?.projectId || skipToken
  );

  const elements = [
    { title: "Details", element: ticket?.details },
    { title: "Creator", element: creator?.username },
    { title: "Assigned to", element: assignee?.username },
    { title: "Project", element: project?.name },
    {
      title: "Priority",
      element: getTicketPriority(ticket?.priority ?? 0).level,
    },
    {
      title: "Severity",
      element: getTicketSeverity(ticket?.severity ?? 0).level,
    },
    { title: "Status", element: getTicketStatus(ticket?.status ?? 0).level },
    {
      title: "Creation date",
      element: format(new Date(ticket?.createdAt ?? 0), "MMMM dd, yyyy"),
    },
  ];
  return elements.map(({ title, element }) => ({
    title,
    element: String(element),
  }));
};
