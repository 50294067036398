import TextField from "components/TextField/TextField";

export const AddVersionFormElements = () => {
  return [
    {
      title: "Requirements",
      element:
        "Name has be to unique 6 characters long. You can also click add and a name will be autogenerated.",
    },
    {
      title: "Name",
      element: <TextField nolabel name="name" optional={true} />,
    },
  ];
};
