import {
  faCheck,
  faKey,
  faLock,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import { EUserRoles, getRoleNames } from "utils/roles";
import "./TeamUserRow.scss";

type Props<TAction> = {
  content: IUser;
  onRowClicked: (data: TAction) => any;
};

const TeamUserRow =
  (managers: IUser["_id"][], owner: ITeam["owner"]) =>
  ({ content: user, onRowClicked }: Props<TUserAction>) => {
    const roleNames = getRoleNames(user.roles);
    const isOwner = owner === user._id;
    const isTeamManager = managers.includes(user._id);
    const isProjectManager = user.roles.includes(EUserRoles.ProjectManager);
    return (
      <tr className="user-item">
        <th className="user-item__name">{user.username}</th>
        <th className="user-item__roles">{roleNames}</th>
        <th
          className="user-item__manager"
          title="User has project manager rights on team's projects"
        >
          <span>{isTeamManager && <FontAwesomeIcon icon={faCheck} />}</span>
        </th>
        <th className="user-item__owner" title="User has team manager rights">
          <span>{isOwner && <FontAwesomeIcon icon={faCheck} />}</span>
        </th>
        <th className="user-item__controls">
          <span>
            <Button
              style={{
                visibility: isOwner || !isProjectManager ? "hidden" : "visible",
              }}
              onClick={() =>
                onRowClicked({
                  action: "TOGGLE_MANAGE",
                  userId: user._id,
                })
              }
              invert
              title={
                isTeamManager
                  ? "Revoke manager permissions"
                  : "Make team manager"
              }
            >
              <FontAwesomeIcon icon={isTeamManager ? faLock : faKey} />
            </Button>
            <Button
              onClick={() =>
                onRowClicked({
                  action: "DELETE",
                  userId: user._id,
                })
              }
              invert
              title="Remove user from team"
            >
              <FontAwesomeIcon icon={faUserMinus} />
            </Button>
          </span>
        </th>
      </tr>
    );
  };

export default TeamUserRow;
