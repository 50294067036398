import Table from "components/Table/Table";
import { useGetOrganizationMembersQuery } from "features/organizations/organizationApi.slice";
import { useGetProjectsQuery } from "features/projects/projectApi.slice";
import { useGetTeamsQuery } from "features/teams/teamApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import { useNavigate } from "react-router-dom";
import "./Projects.scss";
import ProjectRow from "./Rows/ProjectRow";

type Props = {};

const ProjectsList = (props: Props) => {
  const navigate = useNavigate();
  const { data: users } = useGetOrganizationMembersQuery();
  const { data: projects } = useGetProjectsQuery();
  const {
    data: teams,
    isLoading: isGetTeamsLoading,
    isFetching: isGetTeamsFetching,
    isError: isGetTeamsError,
  } = useGetTeamsQuery();

  const onProjectClicked = (_id: string) => {
    navigate("/projects/" + _id);
  };

  if (isGetTeamsError) return <Error />;
  if (isGetTeamsLoading || isGetTeamsFetching) return <Loader />;

  const getById = (id: string, list: any & { _id: string }[]) =>
    list?.find((u: any & { _id: string }) => u._id === id);

  const projectsWithNames = projects?.map((p) => ({
    creator: getById(p.creatorId, users)?.username || "",
    team: getById(p.teamId, teams)?.name || "",
    ...p,
  }));

  return (
    <PageContainer className="projects-list__container">
      <Table
        className="projects-list__table"
        caption="My projects"
        data={projectsWithNames}
        headerTags={[
          "Name",
          { text: "Description", span: 2 },
          "Team",
          "Creator",
        ]}
        Row={ProjectRow}
        onClicked={onProjectClicked}
      />
    </PageContainer>
  );
};

export default ProjectsList;
