import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLogoutMutation } from "features/auth/authApi.slice";
import Navbar from "layouts/Navbar/Navbar";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./AppLayout.scss";
import Logout from "./Loaders/Logout";

type Props = {};

const AppLayout = (props: Props) => {
  const [logout, { isLoading: isLoggingOut }] = useLogoutMutation();
  const location = useLocation();
  const [navbarOpen, setNavbarOpen] = useState(true);
  const [spinOn, setSpinOn] = useState(false);

  const onNavbarToggle = () => {
    setSpinOn(true);
    setTimeout(() => {
      setSpinOn(false);
    }, 300);
    setNavbarOpen((open) => !open);
  };

  const navbarClosedClass = navbarOpen ? "" : "layout__navbar-closed";
  const layoutCollapsed = navbarOpen
    ? "layout-dimensions"
    : "layout-dimensions-collapsed";

  if (isLoggingOut) return <Logout />;
  return (
    <div className={`layout collapsible-width ${layoutCollapsed}`}>
      <div className={`layout__navbar  ${navbarClosedClass}`}>
        <Navbar
          logout={logout}
          navbarOpen={navbarOpen}
          currentPath={location.pathname}
          onNavbarToggle={onNavbarToggle}
          spinOn={spinOn}
        />
      </div>

      <div className="layout__content">
        <Outlet />
      </div>

      <div className={`layout__author `}>
        <span>Bugoscope by Jose Monge, 2023. All rights reserved.</span>
        <a href="mailto:info@josemonge.dev">
          <FontAwesomeIcon icon={faMailBulk} />
          Contact me.
        </a>
        <span>Credits to:</span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://icons8.com/icon/iOtNOPenX2Qg/bug"
        >
          Bug
        </a>{" "}
        icon by{" "}
        <a target="_blank" rel="noreferrer" href="https://icons8.com">
          Icons8
        </a>
      </div>
    </div>
  );
};

export default AppLayout;
