import Table from "components/Table/Table";
import "./History.scss";
import { HistoryRow } from "./HistoryRow";

type Props = {
  className?: string;
  history: ITicket["history"];
  ticketUpdatedAt?: string;
};

const TicketHistory = ({ className, history, ticketUpdatedAt }: Props) => {
  return (
    <Table
      nocaption
      className={className}
      caption="Ticket history:"
      headerTags={["Property", "Old Value", "New Value", "Date Changed"]}
      data={history}
      Row={HistoryRow(ticketUpdatedAt)}
    />
  );
};

export default TicketHistory;
