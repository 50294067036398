export const EUserRoles: Readonly<Record<string, number>> = {
  Developer: 2353,
  Manager: 3455,
  Admin: 9999,
  Disabled: 0,
};

export const EUserPromotionRoles: Readonly<Record<string, number>> = {
  Manager: 3455,
  Admin: 9999,
};

// HUGE TYPESCRIPT BUG: Careful! (valid on 4.9)
// const mutate = (roles: Record<string, number>) => {
//   roles.RegularUser = 0;
// };

// mutate(EUserRoles);

export const getRoleNames = (userRoles: number[]) =>
  Object.entries(EUserRoles)
    .filter(([_, code]) => Math.max(...userRoles) === code)
    .map(([role, _]) => role)
    .join(" / ");
