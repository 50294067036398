import Table from "components/Table/Table";
import {
  useDeleteTeamByIdMutation,
  useGetTeamsQuery,
  useUpdateTeamByIdMutation,
} from "features/teams/teamApi.slice";
import ErrorPanel from "layouts/Panels/ErrorPanel";
import TeamRow from "pages/Teams/Rows/TeamRow";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mutationFailHandler } from "utils/requests";

type Props = {
  isLoading: boolean;
  refetch: ReturnType<typeof useGetTeamsQuery>["refetch"];
  myTeams?: ITeam[];
  selectTeam: React.Dispatch<React.SetStateAction<string | null>>;
};

const TeamsList = ({ isLoading, refetch, myTeams, selectTeam }: Props) => {
  const [updateTeam, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateTeamByIdMutation();
  const [deleteTeam, { isLoading: isDeleteLoading, isError: isDeleteError }] =
    useDeleteTeamByIdMutation();

  const onTeamClicked: React.ComponentProps<
    typeof TeamRow
  >["onRowClicked"] = async (teamClick) => {
    switch (teamClick.action) {
      case "delete":
        selectTeam(null);
        await deleteTeam(teamClick.teamId)
          .unwrap()
          .then(async (response) => {
            toast.warn("Team has been deleted!");
            await refetch();
            return response;
          })
          .catch((error) => {
            toast.error("Error while deleting a team");
            mutationFailHandler("Team")(error);
          });
        break;

      case "select":
        selectTeam(teamClick.teamId);
        break;

      case "saveName":
        if (teamClick?.data && teamClick?.data?.length > 0) {
          selectTeam(null);
          await updateTeam({
            id: teamClick.teamId,
            update: { name: teamClick?.data },
          })
            .unwrap()
            .then(async (response) => {
              toast.info("Team's name has been updated!");
              await refetch();
              return response;
            })
            .catch((error) => {
              toast.error("Error while updating team's name");
              mutationFailHandler("Team")(error);
            });
        }
        break;
    }
  };
  if (isDeleteError || isUpdateError) return <ErrorPanel />;
  return (
    <div className="teams__list">
      <Table
        loading={isLoading || isDeleteLoading || isUpdateLoading}
        caption="My teams"
        data={myTeams}
        headerTags={["Name", "Creation Date", "Controls"]}
        Row={TeamRow}
        onClicked={onTeamClicked}
      />
      {/* <ToastContainer /> */}
    </div>
  );
};

export default TeamsList;
