import { faBomb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Error.scss";

type Props = {};
const Error = (props: Props) => {
  return (
    <div className="error-container">
      <FontAwesomeIcon icon={faBomb} beat shake />
      <strong className="error-legend">
        Oops...Something went wrong&nbsp;
      </strong>
    </div>
  );
};

export default Error;
