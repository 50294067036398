import { skipToken } from "@reduxjs/toolkit/dist/query";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import {
  useGetTicketByIdQuery,
  useUpdateTicketByIdMutation,
} from "features/tickets/ticketApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import { Navigate, useParams } from "react-router-dom";
import { getTicketFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";
import { EditTicketFormElements } from "../TicketById/Card/EditTicketElements";

type Props = {};

const TicketEdit = (props: Props) => {
  const params = useParams();
  const ticketId = params.ticketId;
  const {
    data: ticket,
    isLoading: isGetTicketLoading,
    isFetching: isGetTicketFetching,
    isError: isGetTicketError,
    refetch,
  } = useGetTicketByIdQuery(ticketId || skipToken);
  const [
    updateTicket,
    { isLoading: isCreateTicketLoading, isError: isCreateTicketError },
  ] = useUpdateTicketByIdMutation();

  if (!ticketId || !ticket) return <Navigate to={"/tickets"} />;

  const isError = isCreateTicketError || isGetTicketError;
  const isLoading =
    isCreateTicketLoading || isGetTicketFetching || isGetTicketLoading;

  const onSubmitTicketUpdate: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const editFormObject = getTicketFormData(e.currentTarget);
    let ticketChanges: Partial<ITicket> = {};
    for (const prop in ticket) {
      if (ticket[prop] !== editFormObject[prop])
        ticketChanges[prop] = editFormObject[prop];
    }
    //Required
    ticketChanges["projectId"] = editFormObject["projectId"];
    ticketChanges["versionId"] = editFormObject["versionId"];
    ticketChanges["_id"] = editFormObject["_id"];
    console.log("ticketChanges: ", ticketChanges);
    await updateTicket(ticketChanges)
      .unwrap()
      .catch(mutationFailHandler("Ticket"));
    await refetch();
  };

  const controls = (
    <>
      <Button size="sm" invert bold to={`/tickets/${ticket._id}`} type="button">
        Back to ticket
      </Button>
      <Button type="submit" size="sm" invert bold loading={isLoading}>
        Update ticket
      </Button>
    </>
  );

  if (isLoading) return <Loader />;
  if (isError) return <Error />;
  return (
    <PageContainer>
      <form onSubmit={onSubmitTicketUpdate}>
        <Card<ITicket>
          isLoading={isGetTicketLoading}
          title={`Ticket issue: ${ticket.name}`}
          data={ticket}
          elements={EditTicketFormElements}
          controls={controls}
        />
      </form>
    </PageContainer>
  );
};

export default TicketEdit;
