import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AUTH_COOKIE_EXP_TIME } from "utils/constants";
import { selectAuthLoggedIn, setCredentials } from "./auth.slice";
import { useRefreshQuery } from "./authApi.slice";

type Props = {};

const AuthGuard = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(selectAuthLoggedIn);
  // skipToken avoids unneeded rerendering for the AuthGuard
  // Refetch should be done only after the cookie time has expired
  const {
    isSuccess: isRefreshSuccess,
    isError: isRefreshError,
    data: refreshAuthData,
    isUninitialized,
    refetch,
  } = useRefreshQuery(skipToken);

  useEffect(() => {
    // Refreshing interval matches authentication cookie expiration on server
    const timer = setInterval(() => {
      !isRefreshError && !isUninitialized && refetch();
    }, AUTH_COOKIE_EXP_TIME);

    // Timer has to be reset every time so that
    // an infinite loop isn't created with the useEffect
    return () => {
      clearInterval(timer);
    };
  }, [isRefreshSuccess, isUninitialized, isRefreshError, refetch]);

  if (!loggedIn || isRefreshError)
    return <Navigate to={"/login"} state={{ from: location }} replace />;

  if (isRefreshSuccess) dispatch(setCredentials(refreshAuthData));
  return <Outlet />;
};

export default AuthGuard;
