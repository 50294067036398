import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { EUserRoles } from "utils/roles";

const initialState: IAuthState = {
  token: null,
  admin: false,
  manager: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => initialState,
    setCredentials: (state, action) => {
      const { accessToken, roles } = action.payload;
      state.token = accessToken;
      state.admin = roles.includes(EUserRoles.Admin);
      state.manager = roles.includes(EUserRoles.ProjectManager);
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export const selectAuthLoggedIn = (state: RootState) => !!state.auth.token;
export const selectAuthIsAdmin = (state: RootState) => state.auth.admin;
export const selectAuthIsManager = (state: RootState) => state.auth.manager;
export const selectCurrentToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
