import React from "react";
import "./PageContainer.scss";

type Props = {} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const PageContainer = ({ children, className, ...props }: Props) => {
  return (
    <section className={`${className} page-container`} {...props}>
      {children}
    </section>
  );
};

export default PageContainer;
