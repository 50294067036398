import React from "react";
import { capitalize } from "utils/stringManip";
import "./TextField.scss";

type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type Props =
  | TextAreaProps & {
      nolabel?: boolean;
      cols?: number;
      rows?: number;
    };

const TextAreaField = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ nolabel = false, cols, rows, ...props }: Props, ref) => {
    return (
      <label htmlFor={props.name} className="textfield">
        {!nolabel && `${capitalize(props.name)}:`}
        {
          <textarea
            onChange={props.onChange}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            id={props.name}
            name={props.name}
            placeholder={capitalize(props.name)}
            cols={cols}
            rows={rows}
            required
            autoComplete="off"
            {...props}
          ></textarea>
        }
      </label>
    );
  }
);

export default TextAreaField;
