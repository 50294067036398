import Button from "components/Button/Button";
import PublicPage from "layouts/PublicPage/PublicPage";

type Props = {};

const Welcome = (props: Props) => {
  return (
    <PublicPage
      title="Welcome to Bugoscope: The bug tracker app"
      description="This tool provides developer teams a bugs tracking management system,
    including multiple user roles, ownership and priority."
    >
      <div className="public__card-controls">
        <Button invert to={"/login"}>
          Login
        </Button>
        <Button invert to={"/register"}>
          Sign Up
        </Button>
        <Button invert to={"/dashboard"}>
          Dashboard
        </Button>
      </div>
    </PublicPage>
  );
};

export default Welcome;
