import { faBug, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Loader.scss";

type Props = {};

const Loader = (props: Props) => {
  return (
    <div className="loader-container">
      <FontAwesomeIcon icon={faBug} bounce />
      <strong className="loader-legend">
        Loading
        <FontAwesomeIcon
          className="loader-legend__icon-slowest"
          icon={faCircle}
          beatFade
          transform="shrink-10 down-4"
        />
        <FontAwesomeIcon
          className="loader-legend__icon-faster"
          icon={faCircle}
          beatFade
          transform="shrink-10 down-4"
        />
        <FontAwesomeIcon
          className="loader-legend__icon-fastest"
          icon={faCircle}
          beatFade
          transform="shrink-10 down-4"
        />
      </strong>
    </div>
  );
};

export default Loader;
