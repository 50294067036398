import { useGetOrganizationMembersQuery } from "features/organizations/organizationApi.slice";
import { useGetTeamsQuery } from "features/teams/teamApi.slice";
import Error from "layouts/Error/Error";
import PageContainer from "layouts/PageContainer/PageContainer";
import { useState } from "react";
import AddTeamUser from "./Create/AddTeamUser";
import CreateTeam from "./Create/CreateTeam";
import TeamsList from "./Lists/TeamsList";
import UsersList from "./Lists/UsersList";
import "./Teams.scss";

type Props = {};

const Teams = (props: Props) => {
  const [viewTeamId, setViewTeamId] = useState<string | null>(null);
  const {
    refetch: refetchTeams,
    data: myTeams,
    isLoading: isGetTeamsLoading,
    isFetching: isGetTeamsFetching,
    isError: isGetTeamsError,
  } = useGetTeamsQuery();

  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
    isError: isUsersError,
    refetch: refetchUsers,
  } = useGetOrganizationMembersQuery();

  const viewTeam = myTeams?.filter((team) => team._id === viewTeamId)[0];

  const isDataLoading =
    isGetTeamsFetching ||
    isGetTeamsLoading ||
    isUsersFetching ||
    isUsersLoading;

  const isDataError = isGetTeamsError || isUsersError;

  if (isDataError) return <Error />;
  return (
    <PageContainer className="teams__container">
      <TeamsList
        isLoading={isGetTeamsFetching || isGetTeamsLoading}
        refetch={refetchTeams}
        selectTeam={setViewTeamId}
        myTeams={myTeams}
      />
      <CreateTeam refetch={refetchTeams} />
      {viewTeamId && viewTeam ? (
        <>
          <UsersList
            users={users ?? []}
            isDataLoading={isDataLoading}
            refetchUsers={refetchUsers}
            refetchTeams={refetchTeams}
            team={viewTeam}
            isDataError={isDataError}
          />
          <AddTeamUser
            users={users ?? []}
            isUsersLoading={isDataLoading}
            refetchUsers={refetchUsers}
            refetchTeams={refetchTeams}
            team={viewTeam}
          />
        </>
      ) : (
        <div className="teams__users-list"></div>
      )}
    </PageContainer>
  );
};

export default Teams;
