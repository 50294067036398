import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import TextField from "components/TextField/TextField";
import { useCreateOrganizationMutation } from "features/organizations/organizationApi.slice";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";

type TRegHandler = React.FormEventHandler<HTMLFormElement>;
type Props = {
  directorId: string;
  setOperation: React.Dispatch<React.SetStateAction<TOperationType>>;
};

const CreateOrganizationForm = ({ directorId, setOperation }: Props) => {
  const [
    createOrganization,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateOrganizationMutation();

  const onCreateOrganizationSubmit: TRegHandler = async (e) => {
    e.preventDefault();
    const orgForm = getFormData<IOrganizationCreate>(e.currentTarget);
    const orgResponse = await createOrganization(orgForm)
      .unwrap()
      .then((response) => {
        toast.success(`Organization ${orgForm.name} successfully created!`);
        return response;
      })
      .catch(
        mutationFailHandler("Organization", (msg, error) => {
          toast.error(msg);
          console.log("error :>> ", error);
        })
      );
    console.log("orgResponse :>> ", orgResponse);
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setOperation("create");
    }
  }, [isCreateSuccess, setOperation]);

  return (
    <form className="membership-form" onSubmit={onCreateOrganizationSubmit}>
      <input type="text" name="directorId" value={directorId} hidden readOnly />
      <FlexCenter>
        <TextField nolabel name="name" required minLength={6} />
        <Button
          size="md"
          bold
          type="submit"
          loading={isCreateLoading}
          disabled={isCreateLoading}
        >
          Create
        </Button>
      </FlexCenter>
    </form>
  );
};

export default CreateOrganizationForm;
