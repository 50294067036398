export const options = (title: string, legendOn: boolean) => ({
  responsive: true,
  plugins: {
    legend: {
      display: legendOn,
      position: "right" as const,
    },
    title: {
      display: false,
      text: title,
    },
  },
});

export const data = (
  labels: string[],
  label: string,
  data: number[],
  colors: string[]
) => ({
  labels,
  datasets: [
    {
      label,
      data,
      // borderColor: "rgb(53, 162, 235)",
      backgroundColor: colors, //"rgba(53, 162, 235, 1)",
    },
    // {
    //   label: "Dataset 2",
    //   data,
    // borderColor: "rgb(255, 99, 132)",
    // backgroundColor: "rgba(255, 99, 132, 0.5)",
    // },
  ],
});
