import { format } from "date-fns";

type Props = {
  content: IHistory;
  onRowClicked: (data: any) => any;
};

export const HistoryRow =
  (ticketUpdatedAt?: string) =>
  ({ content: history }: Props) => {
    return (
      <tr className="history-item">
        <th className="history-item__property">{history.property}</th>
        <th className="history-item__newvalue">{history.newValue}</th>
        <th className="history-item__oldvalue">{history.oldValue}</th>
        <th className="history-item__createdAt">
          {history?.createdAt
            ? format(new Date(history.createdAt), "MMMM dd, yyyy")
            : format(new Date(ticketUpdatedAt ?? 0), "MMMM dd, yyyy")}
        </th>
      </tr>
    );
  };
