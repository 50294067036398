import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { useCreateProjectMutation } from "features/projects/projectApi.slice";
import { useRef } from "react";
import { toast } from "react-toastify";
import { getFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";
import { NewProjectFormElements } from "./NewProjectElements";

type Props = {
  className?: string;
  isLoading: boolean;
  teams: ITeam[];
  myUser: IUser;
};

const ProjectNewForm = ({ className, isLoading, teams, myUser }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [createProject, { isLoading: isCreateProjectLoading }] =
    useCreateProjectMutation();

  const onSubmitProjectCreate: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const projectData = getFormData<IProjectCreate>(e.currentTarget);
    await createProject(projectData)
      .unwrap()
      .then((response) => {
        toast.success(`Project ${response.name} has been created!`);
        return response;
      })
      .catch(
        mutationFailHandler("Project", (msg) =>
          toast.error(`Project creation failed: ${msg}`)
        )
      );
    formRef.current?.reset();
  };
  const controls = (
    <Button
      type="submit"
      size="sm"
      invert
      bold
      loading={isCreateProjectLoading}
    >
      <FontAwesomeIcon icon={faFolderPlus} />
      &nbsp;Create project
    </Button>
  );

  return (
    <form ref={formRef} onSubmit={onSubmitProjectCreate} className={className}>
      <Card
        title={"New project:"}
        elements={NewProjectFormElements(isLoading, myUser, teams)}
        controls={controls}
      />
    </form>
  );
};

export default ProjectNewForm;
