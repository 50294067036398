import {
  IconDefinition,
  faBug,
  faCaretLeft,
  faCaretRight,
  faDashboard,
  faDiagramProject,
  faDoorClosed,
  faDoorOpen,
  faFolderPlus,
  faGear,
  faListCheck,
  faPeopleArrows,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiSlice } from "app/api/api.slice";
import Button from "components/Button/Button";
import {
  logOut,
  selectAuthIsAdmin,
  selectAuthIsManager,
} from "features/auth/auth.slice";
import { useLogoutMutation } from "features/auth/authApi.slice";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import NavbarLink from "./NavbarLink/NavbarLink";

type Props = {
  currentPath: string;
  navbarOpen: boolean;
  onNavbarToggle: React.MouseEventHandler<HTMLSpanElement>;
  spinOn: boolean;
  logout: ReturnType<typeof useLogoutMutation>[0];
};
type TNavbarLink = {
  text: string;
  icon: IconDefinition;
  path: string;
};
const Navbar = ({
  navbarOpen,
  currentPath,
  onNavbarToggle,
  spinOn,
  logout,
}: Props) => {
  const dispatch = useDispatch();
  const isManager = useSelector(selectAuthIsManager);
  const isAdmin = useSelector(selectAuthIsAdmin);
  const navigate = useNavigate();
  const { data: user, isSuccess } = useGetMyUserQuery();
  const navbarOpenIcon = navbarOpen ? "" : "hide";
  const navbarCloseIcon = !navbarOpen ? "" : "hide";
  const textCollapseClasses = navbarOpen ? "" : "text-collapsed";
  const invisibleWhenClosed = navbarOpen ? "" : "invisible";
  const links: TNavbarLink[] = [
    { text: "Dashboard", icon: faDashboard, path: "/dashboard" },
    // Director(admin) user links
    ...(isAdmin
      ? [
          { text: "Organization", icon: faPeopleArrows, path: "/org" },
          { text: "My Teams", icon: faUsers, path: "/teams" },
        ]
      : []),
    // Project Manager user links
    ...(isManager || isAdmin
      ? [
          {
            text: "Create new project",
            icon: faFolderPlus,
            path: "/projects/new",
          },
          { text: "My Projects", icon: faDiagramProject, path: "/projects" },
        ]
      : []),
    // Regular user links
    { text: "Create new ticket", icon: faBug, path: "/tickets/new" },
    { text: "My Tickets", icon: faListCheck, path: "/tickets" },
    { text: "User Profile", icon: faUser, path: "/profile" },
  ];

  const onLogoutClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    try {
      await logout()
        .unwrap()
        .then(() => {
          dispatch(logOut());
          dispatch(apiSlice.util.resetApiState());
        });
    } catch (err) {
      console.error(err);
    }
    navigate("/");
  };

  return (
    <>
      <section className="navbar__legend">
        <div className="navbar__legend-container">
          <div className="navbar__legend-title">
            <FontAwesomeIcon icon={faGear} spin={spinOn} />
            <span className={`navbar__title-name ${textCollapseClasses}`}>
              Bugoscope
            </span>
          </div>
          <span
            className="navbar__btn-close"
            onClick={onNavbarToggle}
            title={navbarOpenIcon ? "Open Navbar" : "Close Navbar"}
          >
            <FontAwesomeIcon
              icon={faCaretLeft}
              className={`navbar__legend-icon ${navbarOpenIcon}`}
            />
            <FontAwesomeIcon
              icon={faCaretRight}
              className={`navbar__legend-icon ${navbarCloseIcon}`}
            />
          </span>
        </div>
      </section>

      <p className={`navbar__username ${invisibleWhenClosed}`}>
        Welcome{" "}
        {isSuccess && (
          <Link className="navbar__username-link" to={"/profile"}>
            {user?.username}
          </Link>
        )}
        !
      </p>

      <ul className="navbar__links-list">
        {links.map(({ text, icon, path }) => (
          <NavbarLink
            key={path}
            text={text}
            icon={icon}
            path={path}
            currentPath={currentPath}
            extraClasses={textCollapseClasses}
          />
        ))}
      </ul>
      <div className="navbar__bottom">
        <Button
          size="lg"
          invert
          bold
          className="navbar__logout"
          onClick={onLogoutClick}
          title="Logout"
        >
          <FontAwesomeIcon icon={faDoorClosed} className="doorClosedShow" />
          <FontAwesomeIcon icon={faDoorOpen} className="doorOpenShow" />
          <span className={textCollapseClasses}>Logout</span>
        </Button>
      </div>
    </>
  );
};

export default Navbar;
