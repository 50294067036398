import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useId } from "react";
import "./Card.scss";

type Props<TData> = {
  isLoading?: boolean;
  className?: string;
  title: string;
  data?: TData;
  elements: TCardElements<TData>;
  controls: JSX.Element;
  direction?: "row" | "column";
};

const Card = function <TData>({
  isLoading = false,
  className,
  title,
  data,
  elements,
  controls,
  direction,
}: Props<TData>) {
  const id = useId();
  const dataElements = elements(data ?? ({} as unknown as any));
  return (
    <div className={`card__container ${className}`}>
      <div className="card__title">
        {title.length > 40 ? title.slice(0, 40) + "..." : title}
      </div>
      <div
        className={`card__content ${
          direction === "row" ? "card__content-row" : "card__content-column"
        }`}
      >
        {isLoading ? (
          <div className="card__element">
            Loading... <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          dataElements.map((element) => (
            <div
              key={`${id}-${element.title}`}
              className="card__element"
              style={
                !element?.span
                  ? {} //do nothing
                  : direction === "row"
                  ? // expand on the direction of the respective row/column
                    { gridRow: `1 / ${1 + element?.span ?? 1}` }
                  : { gridColumn: `1 / ${1 + element?.span ?? 1}` }
              }
            >
              <div className="card__element-title">{element.title}</div>
              <hr />
              <div className="card__element-data">{element.element}</div>
            </div>
          ))
        )}
      </div>

      <div className="card__footer">{controls}</div>
    </div>
  );
};

export default Card;
