export const mutationFailHandler =
  (name: string, callback?: (msg: string, error?: any) => void) =>
  (error: any) => {
    console.log("error in mutation:", error);
    let msg = "";
    const status = error?.originalStatus || error?.status;
    switch (status) {
      case 400:
        msg = `Incorrect ${name} ID parameter`;
        break;
      case 404:
        msg = `${name} not found`;
        break;
      case 409:
        msg = `${name} name already in use`;
        break;
      case 500:
        msg = "Internal Server error";
        break;
      default:
        msg = "No server response";
        break;
    }
    console.log(msg);
    callback && callback(msg, error);
  };
