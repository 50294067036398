import { useGetTeamsQuery } from "features/teams/teamApi.slice";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import ProjectNewForm from "./Forms/ProjectNewForm";
import "./ProjectNew.scss";

type Props = {};

const ProjectNew = (props: Props) => {
  const { data: myUser, isLoading: isGetMyUserLoading } = useGetMyUserQuery();
  const {
    data: teams,
    isLoading: isGetTeamsLoading,
    isFetching: isGetTeamsFetching,
    isError: isGetTeamsError,
  } = useGetTeamsQuery();

  if (isGetTeamsError) return <Error />;
  if (isGetTeamsLoading || isGetTeamsFetching || !teams || !myUser)
    return <Loader />;
  return (
    <PageContainer className="project-new__container">
      <ProjectNewForm
        className="project-new__component"
        isLoading={
          isGetTeamsLoading || isGetMyUserLoading || isGetTeamsFetching
        }
        teams={teams}
        myUser={myUser}
      />
    </PageContainer>
  );
};

export default ProjectNew;
