import React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {};

export const FlexCenter = ({ children, ...props }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "var(--flex-gap)",
      }}
      {...props}
    >
      {children}
    </div>
  );
};
