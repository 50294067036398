import { faLock, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import { EUserRoles } from "utils/roles";
import { FlexCenter } from "components/FlexBox/FlexCenter";

type Props<TAction> = {
  content: IUser;
  onRowClicked: (data: TAction) => any;
};

const RequestorRow =
  (isLoading: boolean) =>
  ({ content: user, onRowClicked }: Props<TUserMemberAction>) => {
    const isUserDisabled = user.roles.includes(EUserRoles.Disabled);
    const buttonTooltip = isUserDisabled ? "Enable user" : "Disable user";
    const userMemberAction: TUserMemberAction = {
      type: isUserDisabled ? "enable" : "disable",
      userId: user._id,
    };
    return (
      <tr className="user-item">
        <th className="user-item__name" colSpan={3}>
          {user.username}
        </th>
        <th className="user-item__controls">
          <FlexCenter>
            {user.roles.includes(EUserRoles.Admin) ? null : (
              <Button
                loading={isLoading}
                onClick={() => onRowClicked(userMemberAction)}
                invert
                title={buttonTooltip}
              >
                <FontAwesomeIcon icon={isUserDisabled ? faLock : faKey} />
              </Button>
            )}
          </FlexCenter>
        </th>
      </tr>
    );
  };

export default RequestorRow;
