import TabGroup from "components/TabGroup/TabGroup";
import { useGetMyOrganizationQuery } from "features/organizations/organizationApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import "./Organization.scss";
import Requests from "./Requests";
import Roles from "./Roles";

type Props = {};

const OrganizationAndRoles = (props: Props) => {
  const {
    data: organization,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetMyOrganizationQuery();

  if (isFetching || isLoading || !organization) return <Loader />;
  if (isError) return <Error />;
  return (
    <PageContainer>
      <TabGroup
        tabNames={["Membership Requests", "Roles"]}
        tabs={[
          <Requests
            members={organization.members}
            requests={organization.membershipRequests}
            isLoading={isLoading}
            isFetching={isFetching}
            refetch={refetch}
          />,
          <Roles
            members={organization.members}
            isLoading={isLoading}
            isFetching={isFetching}
            refetch={refetch}
          />,
        ]}
      />
    </PageContainer>
  );
};

export default OrganizationAndRoles;
