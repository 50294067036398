import Select from "components/Select/Select";

export function NewUsersElements(
  users: { id: string; username: string }[] | undefined
) {
  return [
    {
      title: "Requirements",
      element:
        "Select the users needed and update roles in the team`s users list." +
        "Only users with project manager roles can manage teams, " +
        "which gives them permissions to view and create projects, assign tickets and modify priorities.",
    },
    {
      title: "Name",
      element: users ? (
        <Select<{ id: string; username: string }>
          name="users"
          multiple
          size={3}
          list={users}
          optionValue={"id"}
          optionText={"username"}
        />
      ) : (
        <span>No users found</span>
      ),
    },
  ];
}
