import Table from "components/Table/Table";
import { useNewestTickets } from "hooks/useNewestTickets";
import { useTicketCounts } from "hooks/useTicketCounts";
import {
  chartColors,
  levelColors,
  priorityLevels,
  severityLevels,
  statusOptions,
  ticketTypes,
} from "utils/ticket/settings";
import "./Dashboard.scss";
import PageContainer from "layouts/PageContainer/PageContainer";
import TicketChart from "./Chart/Chart";
import { useNavigate } from "react-router-dom";
import Loader from "layouts/Loaders/Loader";
import NewestTicketRow from "./Rows/NewestTicketRow";

type Props = {};

const Dashboard = (props: Props) => {
  const navigate = useNavigate();
  const { newestTickets, isLoading } = useNewestTickets();
  const counts = useTicketCounts();

  const onTicketClicked = (_id: string) => {
    navigate("/tickets/" + _id);
  };
  if (isLoading) return <Loader />;
  return (
    <PageContainer>
      <div className="dashboard-grid__container">
        <div className="dashboard-grid__element dashboard-grid__controls">
          <Table
            headerTags={["Name", "Status", "Priority", "Severity"]}
            Row={NewestTicketRow}
            caption={"Newest tickets:"}
            data={newestTickets}
            onClicked={onTicketClicked}
          />
        </div>
        <div className="dashboard-grid__element dashboard-grid__priority">
          <TicketChart
            labels={priorityLevels}
            title="Tickets by Priority"
            type="bar"
            rawData={Object.values(counts["priority"])}
            colors={levelColors}
          />
        </div>
        <div className="dashboard-grid__element dashboard-grid__type">
          <TicketChart
            labels={ticketTypes}
            title="Tickets by Type"
            type="doughnut"
            rawData={Object.values(counts["type"])}
            colors={chartColors}
            legendOn
          />
        </div>
        <div className="dashboard-grid__element dashboard-grid__severity">
          <TicketChart
            labels={severityLevels}
            title="Tickets by Severity"
            type="bar"
            rawData={Object.values(counts["severity"])}
            colors={levelColors}
          />
        </div>
        <div className="dashboard-grid__element dashboard-grid__status">
          <TicketChart
            labels={statusOptions}
            title="Tickets by Status"
            type="doughnut"
            rawData={Object.values(counts["status"])}
            colors={chartColors}
            legendOn
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Dashboard;
