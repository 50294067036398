import { apiSlice } from "app/api/api.slice";
import { API_VERSION } from "utils/constants";

// const teamsAdapter = createEntityAdapter<ITeam>({
//   // Assume IDs are stored in a field other than `team.id`
//   selectId: (team) => team._id,
// });

// const initialState = teamsAdapter.getInitialState();

export const teamsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamById: builder.query<ITeam, ITeam["_id"]>({
      query: (teamId) => ({
        url: `/api/${API_VERSION}/teams/${teamId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [
        { type: "Team", id: "TEAM_LIST" },
        { type: "Team", id },
      ],
    }),
    getTeams: builder.query<ITeam[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/teams/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({ type: "Team" as const, id })),
              { type: "Team", id: "TEAM_LIST" },
            ]
          : [{ type: "Team", id: "TEAM_LIST" }],
    }),
    createTeam: builder.mutation<ITeam, ITeamCreate>({
      query: (team: ITeamCreate) => ({
        url: `/api/${API_VERSION}/teams/`,
        method: "POST",
        body: team,
      }),
      invalidatesTags: () => [{ type: "Team", id: "TEAM_LIST" }],
    }),
    updateTeamById: builder.mutation<ITeam, ITeamUpdate>({
      query: ({ id, update }) => ({
        url: `/api/${API_VERSION}/teams/${id}`,
        method: "PUT",
        body: update,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Team", id: "TEAM_LIST" },
        { type: "Team", id },
      ],
    }),
    deleteTeamById: builder.mutation<any, ITeam["_id"]>({
      query: (teamId: ITeam["_id"]) => ({
        url: `/api/${API_VERSION}/teams/${teamId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, teamId) => [
        { type: "Team", id: "TEAM_LIST" },
        { type: "Team", id: teamId },
      ],
    }),
  }),
});

export const {
  useGetTeamByIdQuery,
  useGetTeamsQuery,
  useCreateTeamMutation,
  useDeleteTeamByIdMutation,
  useUpdateTeamByIdMutation,
} = teamsApiSlice;

// export const selectTeamsResult = teamsApiSlice.endpoints.getTeams.select();

// const selectTeamsData = createSelector(
//   selectTeamsResult,
//   (teamsResult) => teamsResult.data // normalized state object with ids & entities
// );

// export const {
//   selectAll: selectAllTeams,
//   selectById: selectTeamById,
//   selectIds: selectTeamIds,
//   // Pass in a selector that returns the teams slice of state
// } = teamsAdapter.getSelectors<RootState[apiSlice.reducerPath]>(
//   (state) => selectTeamsData(state[apiSlice.reducerPath]) ?? initialState
// );

// export const selectCurrentTeam = (state: RootState) => state.auth.team;
