import { faMap } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";

type Props = {};

const NotFound = (props: Props) => {
  return (
    <div className="error-container flex-column">
      <div>
        <FontAwesomeIcon icon={faMap} />
        <strong className="error-legend">
          The page you're looking for doesn't exist
        </strong>
      </div>
      <span>
        <span className="error-nav">Go to</span>
        <Button className="error-nav" size="md" to={"/dashboard"}>
          Dashboard
        </Button>
        <strong className="error-nav">or</strong>
        <Button className="error-nav" size="md" to={"/login"}>
          Login
        </Button>
      </span>
    </div>
  );
};

export default NotFound;
