import { useEffect, useState } from "react";
import { randomQuote } from "utils/randomQuote";

interface IQuote {
  text: string;
  author: string;
}

export const useQuote = () => {
  const [dailyQuote, setDailyQuote] = useState<IQuote | null>(null);
  useEffect(() => {
    if (!dailyQuote) {
      const quote = randomQuote();
      setDailyQuote(quote);
    }
  }, [dailyQuote]);

  return dailyQuote;
};
