import Table from "components/Table/Table";
import {
  useGetTeamsQuery,
  useUpdateTeamByIdMutation,
} from "features/teams/teamApi.slice";
import { useGetUsersQuery } from "features/users/userApi.slice";
import ErrorPanel from "layouts/Panels/ErrorPanel";
import React, { useCallback } from "react";
import { mutationFailHandler } from "utils/requests";
import TeamUserRow from "../Rows/TeamUserRow";

type Props = {
  isDataError: boolean;
  isDataLoading: boolean;
  team: ITeam;
  users: IUser[];
  refetchTeams: ReturnType<typeof useGetTeamsQuery>["refetch"];
  refetchUsers: ReturnType<typeof useGetUsersQuery>["refetch"];
};

const UsersList = ({
  isDataError,
  isDataLoading,
  users,
  team,
  refetchTeams,
  refetchUsers,
}: Props) => {
  const [updateTeam, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateTeamByIdMutation();

  const onUserClicked: React.ComponentProps<
    ReturnType<typeof TeamUserRow>
  >["onRowClicked"] = async (data) => {
    let teamUpdate;
    switch (data.action) {
      case "TOGGLE_MANAGE":
        teamUpdate = {
          id: team!._id,
          update: { toggleManager: data.userId },
        };
        break;
      case "DELETE":
        teamUpdate = {
          id: team!._id,
          update: {
            users: team!.users.filter(
              (teamUserId) => teamUserId !== data.userId
            ),
          },
        };
        break;
      //This shouldn't happen
      default:
        return;
    }
    await updateTeam(teamUpdate).unwrap().catch(mutationFailHandler("Team"));
    await refetchTeams();
    await refetchUsers();
  };

  const teamUsers = useCallback(
    () => users?.filter(({ _id }) => team!.users.includes(_id)),
    [team, users]
  );

  if (isDataError || isUpdateError) return <ErrorPanel />;
  return (
    <Table
      loading={isDataLoading || isUpdateLoading}
      className="teams__users-list"
      caption={`Team ${team?.name} users`}
      data={teamUsers()}
      headerTags={["Username", "Roles", "Team manager", "Owner", "Controls"]}
      Row={TeamUserRow(team?.managers ?? [], team?.owner ?? "")}
      onClicked={onUserClicked}
    />
  );
};

export default UsersList;
