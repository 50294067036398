export const MPriorityLevel = {
  0: "None",
  1: "Low",
  2: "Medium",
  3: "High",
};
export enum EPriorityLevel {
  NONE = "None",
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

export const MSeverityLevel = {
  0: "None",
  1: "Mild",
  2: "Severe",
  3: "Critical",
};
export enum ESeverityLevel {
  NONE = "None",
  MILD = "Mild",
  SEVERE = "Severe",
  CRITICAL = "Critical",
}

export const MStatus = {
  0: "New",
  1: "Open",
  2: "In progress",
  3: "Resolved",
  4: "Addtl. info req.",
};
export enum EStatus {
  NEW = "New",
  OPEN = "Open",
  IN_PROGRESS = "In progress",
  RESOLVED = "Resolved",
  ADDITIONAL_INFO = "Addtl. info req.",
}

export const MTicketType = {
  0: "Bugs/Errors",
  1: "Feature requests",
  2: "Other comments",
  3: "Training/Doc. req.",
};
export enum ETicketType {
  BUGS_ERRORS = "Bugs/Errors",
  FEAT_REQUESTS = "Feature requests",
  OTHER_COMMENTS = "Other comments",
  TRAINING_DOC_REQUESTS = "Training/Doc. req.",
}

export enum ELevelColors {
  BLUE = "#1090FF",
  GREEN = "#6cae75",
  ORANGE = "#ed7d3a",
  RED = "#de3c4b",
}

export const chartColors = [
  "rgba(244, 65, 116, 1)",
  "rgba(250, 126, 97, 1)",
  "rgba(53, 162, 235, 1)",
  "rgba(250, 223, 99, 1)",
  " rgba(189, 211, 88, 1)",
];

// Sequence of values in arrays are defined by the sequence on the enum
// e.g. for EStatus, index 0 in array is mapped to "New"
export const levelColors = Object.values(ELevelColors);
export const ticketTypes = Object.values(ETicketType);
export const priorityLevels = Object.values(EPriorityLevel);
export const severityLevels = Object.values(ESeverityLevel);
export const statusOptions = Object.values(EStatus);

export const priorities = Object.entries(MPriorityLevel);
export const severities = Object.entries(MSeverityLevel);
export const statuses = Object.entries(MStatus);
export const tTypes = Object.entries(MTicketType);

export const getTicketPriority = (priority: number) => {
  return {
    level: priorityLevels[priority],
    color: levelColors[priority],
  };
};

export const getTicketSeverity = (priority: number) => {
  return {
    level: severityLevels[priority],
    color: levelColors[priority],
  };
};

export const getTicketStatus = (priority: number) => {
  return {
    level: statusOptions[priority],
    color: levelColors[priority],
  };
};

export const EStatusMap = {
  NEW: { message: "New", color: "", code: 0 },
  OPEN: { message: "Open", code: 0 },
  IN_PROGRESS: { message: "In progress", code: 0 },
  RESOLVED: { message: "Resolved", code: 0 },
  ADDITIONAL_INFO: { message: "Addtl. info req.", code: 0 },
};
