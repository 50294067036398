import React from "react";
import "./PublicPage.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  title: string;
  description?: string;
};

const PublicPage = ({ title, description, children }: Props) => {
  return (
    <div className="public">
      <div className="public__card">
        <h1 className="public__card-title">{title}</h1>
        {!description ? null : <div>{description}</div>}
        {children}
      </div>
    </div>
  );
};

export default PublicPage;
