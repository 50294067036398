import Select from "components/Select/Select";
import TextAreaField from "components/TextField/TextAreaField";
import TextField from "components/TextField/TextField";

export const EditProjectFormElements =
  (creatorUser: IUser, teams: ITeam[]) => (project?: IProject) => {
    return [
      {
        title: "Name",
        element: (
          <TextField nolabel name="name" defaultValue={project?.name ?? ""} />
        ),
      },
      {
        title: "Assigned Team",
        element: (
          <Select<ITeam>
            name="teamId"
            list={teams}
            optionValue={"_id"}
            optionText="name"
            defaultValue={project?.teamId ?? ""}
          />
        ),
      },
      {
        // span: 2,
        title: "Description",
        element: (
          <TextAreaField
            nolabel
            name="description"
            cols={30}
            rows={6}
            defaultValue={project?.description ?? ""}
          />
        ),
      },
      {
        title: "Creator",
        element: (
          <>
            <TextField nolabel value={`${creatorUser?.username}`} readOnly />
            <TextField
              nolabel
              name="creatorId"
              value={creatorUser?._id}
              readOnly
              hidden
            />
          </>
        ),
      },
    ];
  };
