import { getRoleNames } from "utils/roles";

type Props<TAction> = {
  content: IUser;
  onRowClicked: (data: TAction) => any;
};

const UserRoleRow = ({ content: user, onRowClicked }: Props<TUserAction>) => {
  const roleNames = getRoleNames(user.roles);
  return (
    <tr className="user-item">
      <th className="user-item__name">{user.username}</th>
      <th className="user-item__roles" style={{ textAlign: "center" }}>
        {roleNames}
      </th>
    </tr>
  );
};

export default UserRoleRow;
