import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./NavbarLink.scss";

type props = {
  text: string;
  path: string;
  currentPath: string;
  icon: FontAwesomeIconProps["icon"];
  extraClasses: string;
};
const NavbarLink = ({ text, icon, path, currentPath, extraClasses }: props) => {
  return (
    <li className="navbar-item" key={path} title={text}>
      <Link
        className={`navbar-item__link ${
          currentPath === path ? "highlight-item" : ""
        }`}
        to={path}
      >
        <div className="navbar-item__link-icon fa-2x">
          <FontAwesomeIcon icon={icon} />
        </div>
        <span className={`navbar-item__link-text ${extraClasses}`}>{text}</span>
      </Link>
    </li>
  );
};

export default NavbarLink;
