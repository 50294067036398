import Select from "components/Select/Select";
import TextAreaField from "components/TextField/TextAreaField";
import TextField from "components/TextField/TextField";

export const NewProjectFormElements =
  (isLoading: boolean, creatorUser: IUser, teams: ITeam[]) => () => {
    return [
      {
        title: "Name",
        element: <TextField disabled={isLoading} nolabel name="name" />,
      },
      {
        title: "Creator",
        element: (
          <>
            <TextField
              disabled={isLoading}
              nolabel
              value={`${creatorUser?.username}`}
              readOnly
            />
            <TextField
              disabled={isLoading}
              nolabel
              name="creatorId"
              value={creatorUser?._id}
              readOnly
              hidden
            />
          </>
        ),
      },
      {
        title: "Assigned Team",
        element: (
          <Select<ITeam>
            disabled={isLoading}
            name="teamId"
            list={teams}
            optionValue={"_id"}
            optionText="name"
          />
        ),
      },

      {
        title: "Version",
        element: (
          <TextField
            disabled={isLoading}
            nolabel
            name="versionName"
            optional={true}
          />
        ),
      },
      {
        span: 2,
        title: "Description",
        element: (
          <TextAreaField
            disabled={isLoading}
            nolabel
            name="description"
            cols={30}
            rows={6}
          />
        ),
      },
    ];
  };
