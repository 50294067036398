import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useCreateVersionByProjectIdMutation,
  useDeleteProjectByIdMutation,
  useDeleteVersionByProjectIdMutation,
  useGetProjectByIdQuery,
  useUpdateProjectByIdMutation,
  useUpdateVersionByProjectIdMutation,
} from "features/projects/projectApi.slice";
import { useGetTeamsQuery } from "features/teams/teamApi.slice";
import { useGetUserByIdQuery } from "features/users/userApi.slice";
import { Params } from "react-router-dom";

export const useProject = (params: Readonly<Params<string>>) => {
  const {
    refetch,
    data: project,
    isFetching: isGetProjectFetching,
    isLoading: isGetProjectLoading,
    isError: isGetProjectError,
  } = useGetProjectByIdQuery(params.projectId ?? skipToken);
  const {
    data: user,
    isFetching: isGetUserFetching,
    isLoading: isGetUserLoading,
    isError: isGetUserError,
  } = useGetUserByIdQuery(project?.creatorId ?? skipToken);
  const {
    data: teams,
    isFetching: isGetTeamsFetching,
    isLoading: isGetTeamsLoading,
    isError: isGetTeamsError,
  } = useGetTeamsQuery();
  const [
    updateProject,
    { isLoading: isUpdateProjectLoading, isError: isUpdateProjectError },
  ] = useUpdateProjectByIdMutation();
  const [
    createVersion,
    { isLoading: isCreateVersionLoading, isError: isCreateVersionError },
  ] = useCreateVersionByProjectIdMutation();
  const [
    deleteProject,
    { isLoading: isDeleteProjectLoading, isError: isDeleteProjectError },
  ] = useDeleteProjectByIdMutation();
  const [
    updateVersion,
    { isLoading: isUpdateVersionLoading, isError: isUpdateVersionError },
  ] = useUpdateVersionByProjectIdMutation();
  const [
    deleteVersion,
    { isLoading: isDeleteVersionLoading, isError: isDeleteVersionError },
  ] = useDeleteVersionByProjectIdMutation();

  const isQueryLoading =
    isGetUserFetching ||
    isGetTeamsLoading ||
    isGetTeamsFetching ||
    isGetUserLoading ||
    isGetProjectLoading ||
    isGetProjectFetching;

  const isMutationLoading =
    isUpdateProjectLoading ||
    isDeleteProjectLoading ||
    isCreateVersionLoading ||
    isUpdateVersionLoading ||
    isDeleteVersionLoading;

  const isError =
    isGetProjectError ||
    isGetTeamsError ||
    isGetUserError ||
    isUpdateProjectError ||
    isDeleteProjectError ||
    isCreateVersionError ||
    isUpdateVersionError ||
    isDeleteVersionError;

  const isLoading = isQueryLoading || isMutationLoading;

  return {
    refetch,
    project,
    teams,
    user,
    updateProject,
    deleteProject,
    createVersion,
    updateVersion,
    deleteVersion,
    isLoading,
    isError,
  };
};
