import {
  faClock,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import { useGetProjectByIdQuery } from "features/projects/projectApi.slice";
import { useGetUserByIdQuery } from "features/users/userApi.slice";
import {
  getTicketPriority,
  getTicketSeverity,
  getTicketStatus,
} from "utils/ticket/settings";
import "./TicketRow.scss";

type Props = {
  content: ITicket;
  onRowClicked: (data: any) => any;
};

const TicketRow = ({ content: ticket, onRowClicked }: Props) => {
  const { level: priorityLevel, color } = getTicketPriority(ticket.priority);
  const { level: severityLevel, color: severityColor } = getTicketSeverity(
    ticket.severity
  );
  const { level: statusLevel } = getTicketStatus(ticket.status);
  const { data: project } = useGetProjectByIdQuery(ticket.projectId);
  const { data: assignee } = useGetUserByIdQuery(ticket.assigneeId);
  return (
    <tr
      className="ticket-item"
      // onClick={(e) => navigate(`/tickets/${ticket._id}`)}
    >
      <th className="ticket-item__name">{ticket.name}</th>
      <th className="ticket-item__project">
        {project?.name ?? <FontAwesomeIcon icon={faSpinner} spin />}
      </th>
      <th className="ticket-item__assignee">
        {assignee?.username ?? <FontAwesomeIcon icon={faSpinner} spin />}
      </th>
      <th className="ticket-item__status">{statusLevel}</th>
      <th className="ticket-item__priority">
        <span className="chip" style={{ backgroundColor: color }}>
          <FontAwesomeIcon icon={faClock} /> {priorityLevel}
        </span>
      </th>
      <th className="ticket-item__severity">
        <span style={{ backgroundColor: severityColor }} className="chip">
          <FontAwesomeIcon icon={faTriangleExclamation} /> {severityLevel}
        </span>
      </th>
      <th className="ticket-item__version">
        {project?.version
          .find((v) => v._id === ticket.versionId)
          ?.name.slice(0, 20) + "..." ?? ""}
      </th>
      <th className="ticket-item__controls">
        <FlexCenter>
          <Button to={`/tickets/${ticket._id}`} size="sm">
            Edit ticket
          </Button>
        </FlexCenter>{" "}
      </th>
    </tr>
  );
};

export default TicketRow;
