import Select from "components/Select/Select";

export function SelectRequestsElements(users: IUser[] | undefined) {
  return [
    {
      title: "INSTRUCTIONS",
      element:
        "Select the users needed to get their memberships accepted/rejected. " +
        "Only organization's members can access the assigned project and tickets." +
        "Once a user is accepted into the organization it can be disabled, or enabled" +
        " again. To completely delete the user, contact the system administrator.",
    },
    {
      title: "Name",
      element: users ? (
        <Select<IUser>
          name="users"
          multiple
          size={15}
          list={users}
          optionValue={"_id"}
          optionText={"username"}
        />
      ) : (
        <span>No users found</span>
      ),
    },
  ];
}
