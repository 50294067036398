import Select from "components/Select/Select";
import { EUserPromotionRoles } from "utils/roles";

export function SelectUsersElements(users: IUser[] | undefined) {
  return [
    {
      title: "Step 1)",
      element: "Select the users needed to get their roles updated.",
    },

    {
      title: "Name",
      element: users ? (
        <Select<IUser>
          name="users"
          multiple
          size={10}
          list={users}
          optionValue={"_id"}
          optionText={"username"}
        />
      ) : (
        <span>No users found</span>
      ),
    },
    {
      title: "Step 2)",
      element: "Select the desired role for the users and submit",
    },
    {
      title: "Roles",
      element: (
        <Select<{ code: number; role: string }>
          name="roles"
          list={Object.entries(EUserPromotionRoles).map(([role, code]) => ({
            code,
            role,
          }))}
          optionValue={"code"}
          optionText={"role"}
        />
      ),
    },
  ];
}
