import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import Select from "components/Select/Select";
import {
  useCreateMembershipRequestMutation,
  useGetOrganizationsQuery,
} from "features/organizations/organizationApi.slice";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";

type TRegHandler = React.FormEventHandler<HTMLFormElement>;
type Props = {
  userId: string;
  setOperation: React.Dispatch<React.SetStateAction<TOperationType>>;
};

const JoinRequestForm = ({ setOperation, userId }: Props) => {
  const {
    data: organizations,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
    isError: isGetError,
  } = useGetOrganizationsQuery();

  const [
    createRequest,
    {
      isError: isRequestError,
      isLoading: isRequestLoading,
      isSuccess: isRequestSuccess,
    },
  ] = useCreateMembershipRequestMutation();

  const onJoinRequestSubmit: TRegHandler = async (e) => {
    e.preventDefault();
    const membershipRequest: IMembershipRequest =
      getFormData<IMembershipRequest>(e.currentTarget);
    await createRequest(membershipRequest)
      .unwrap()
      .catch(
        mutationFailHandler("Organization", (msg, error) => {
          toast(msg);
          console.log("error :>> ", error);
        })
      );
  };

  useEffect(() => {
    if (isRequestSuccess) {
      setOperation("request");
    }
  }, [isRequestSuccess, setOperation]);

  if (isGetLoading || isGetFetching)
    return (
      <strong className="membership__status-alert">
        Loading... <FontAwesomeIcon icon={faSpinner} spin />
      </strong>
    );

  if (!organizations || !organizations?.length)
    return (
      <strong className="membership__status-alert">
        No organizations found. Create one first.
      </strong>
    );

  if (isRequestError) {
    return (
      <strong className="membership__status-alert">
        There was an error with your membership request. Try again another time
      </strong>
    );
  }
  if (isGetError)
    return (
      <strong className="membership__status-alert">
        There was an error fetching the organizations.
      </strong>
    );

  return (
    <form className="membership-form" onSubmit={onJoinRequestSubmit}>
      <input type="text" name="userId" value={userId} hidden readOnly />
      <FlexCenter>
        <Select<IOrganization>
          disabled={isGetLoading}
          name="organizationId"
          list={organizations}
          optionValue={"_id"}
          optionText="name"
          defaultValue={""}
        />
        <Button
          size="md"
          bold
          type="submit"
          loading={isRequestLoading || isGetFetching || isGetLoading}
          disabled={isRequestLoading || isGetFetching || isGetLoading}
        >
          Join
        </Button>
      </FlexCenter>
    </form>
  );
};

export default JoinRequestForm;
