import AuthGuard from "features/auth/AuthGuard";
import AppLayout from "layouts/AppLayout";
import NotFound from "layouts/Error/NotFound";
import MinLayout from "layouts/MinLayout";
import Dashboard from "pages/Dashboard/Dashboard";
import Login from "pages/Login/Login";
import Membership from "pages/Membership/Membership";
import Organization from "pages/Organization/Organization";
import Profile from "pages/Profile/Profile";
import ProjectById from "pages/ProjectById/ProjectById";
import ProjectNew from "pages/ProjectNew/ProjectNew";
import Projects from "pages/Projects/Projects";
import Register from "pages/Register/Register";
import Teams from "pages/Teams/Teams";
import TicketById from "pages/TicketById/TicketById";
import TicketEdit from "pages/TicketEdit/TicketEdit";
import TicketNew from "pages/TicketNew/TicketNew";
import Tickets from "pages/Tickets/Tickets";
import Welcome from "pages/Welcome/Welcome";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MinLayout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "membership/:userId",
        element: <Membership />,
      },
      {
        element: <AuthGuard />,
        children: [
          {
            element: <AppLayout />,
            errorElement: <NotFound />,
            children: [
              {
                path: "dashboard",
                element: <Dashboard />,
              },
              {
                path: "teams",
                element: <Teams />,
              },
              {
                path: "profile",
                element: <Profile />,
              },
              {
                path: "projects",
                children: [
                  {
                    index: true,
                    element: <Projects />,
                  },
                  {
                    path: "new",
                    element: <ProjectNew />,
                  },
                  {
                    path: ":projectId",
                    element: <ProjectById />,
                  },
                ],
              },
              {
                path: "tickets",
                children: [
                  {
                    index: true,
                    element: <Tickets />,
                  },
                  {
                    path: "new",
                    element: <TicketNew />,
                  },
                  {
                    path: "edit/:ticketId",
                    element: <TicketEdit />,
                  },
                  {
                    path: ":ticketId",
                    element: <TicketById />,
                  },
                ],
              },
              {
                path: "org",
                element: <Organization />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
