import Table from "components/Table/Table";
import {
  useDeleteVersionByProjectIdMutation,
  useGetProjectByIdQuery,
  useUpdateVersionByProjectIdMutation,
} from "features/projects/projectApi.slice";
import VersionRow from "../Projects/Rows/VersionRow";
import { mutationFailHandler } from "utils/requests";
import { toast } from "react-toastify";

type Props = {
  className: string;
  project: IProject;
  updateVersion: ReturnType<typeof useUpdateVersionByProjectIdMutation>[0];
  deleteVersion: ReturnType<typeof useDeleteVersionByProjectIdMutation>[0];
  refetch: ReturnType<typeof useGetProjectByIdQuery>["refetch"];
};

const VersionList = ({
  className,
  project,
  updateVersion,
  deleteVersion,
  refetch,
}: Props) => {
  const onVersionClicked: React.ComponentProps<
    typeof VersionRow
  >["onRowClicked"] = async (versionClicked) => {
    switch (versionClicked.action) {
      case "delete":
        await deleteVersion({
          projectId: project._id,
          versionId: versionClicked.versionId,
        })
          .unwrap()
          .then((response) => {
            toast.warn("Version deleted successfully!");
            return response;
          })
          .catch((error) => {
            toast.error(`Failed to delete version:${error.data.message}`);
            mutationFailHandler("Project")(error);
          });
        break;
      case "saveName":
        if (versionClicked?.data && versionClicked?.data?.length > 0) {
          await updateVersion({
            projectId: project._id,
            versionId: versionClicked.versionId,
            updates: { name: versionClicked?.data },
          })
            .unwrap()
            .then((response) => {
              toast.info("Version updated successfully!");
              return response;
            })
            .catch((error) => {
              toast.error(`Failed to update version:${error.data.message}`);
              mutationFailHandler("Project")(error);
            });
        }
        break;
    }
    refetch();
  };

  return (
    <Table
      className={className}
      Row={VersionRow}
      caption="Project Versions"
      headerTags={["Name", "Creation Date", "Total Tickets", "Controls"]}
      data={project.version}
      onClicked={onVersionClicked}
    />
  );
};

export default VersionList;
