import {
  FetchArgs,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { logOut, setCredentials } from "features/auth/auth.slice";

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_API_URI,
  credentials: "include",
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState;
    const token = state.auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRefresh: BaseQueryFn<
  string | FetchArgs,
  any,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // const state = api.getState() as RootState;
  // Token refresh won't happen when login (server might return 401 or such errors)
  const notLoginPage =
    result?.meta && !result?.meta.request.url.includes("/auth/login");

  const status =
    result.meta?.response?.status ??
    (result?.error?.status === "PARSING_ERROR" &&
      result?.error?.originalStatus);

  if (status && [401, 403].includes(status) && notLoginPage) {
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);
    if (refreshResult?.data) {
      // store new access token and roles
      api.dispatch(setCredentials(refreshResult.data));
      // retry the original failed query but now with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: baseQueryWithRefresh,
  tagTypes: ["Project", "Team", "Ticket", "User", "Organization"],
  endpoints: (builder) => ({}),
});
