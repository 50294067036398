import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Table from "components/Table/Table";
import { useGetMyOrganizationQuery } from "features/organizations/organizationApi.slice";
import {
  useUpdateAddUsersRoleMutation,
  useUpdateDeleteUsersRoleMutation,
} from "features/users/userApi.slice";
import { useCallback, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { mutationFailHandler } from "utils/requests";
import { SelectUsersElements } from "./Forms/SelectUsersElements";
import UserRow from "./Rows/UserRow";

type Props = {
  members: IUser[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: ReturnType<typeof useGetMyOrganizationQuery>[0];
};

const Roles = ({ members, refetch, isFetching, isLoading }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [addUsersRole, { isLoading: isAddLoading }] =
    useUpdateAddUsersRoleMutation();
  const [deleteUsersRole, { isLoading: isDeleteLoading }] =
    useUpdateDeleteUsersRoleMutation();

  const onUpdateUsersRole = useCallback(
    async (operation: "add" | "delete") => {
      if (!formRef.current) return;
      const updateForm = new FormData(formRef.current);
      const update: IUsersRoleUpdate = {
        role: parseInt(updateForm.get("roles") as string),
        userIds: updateForm.getAll("users") as string[],
      };
      const request =
        operation === "add" ? addUsersRole(update) : deleteUsersRole(update);
      await request
        .unwrap()
        .then((response) => {
          refetch();
          toast.info("Roles update success on selected users!");
          return response;
        })
        .catch(mutationFailHandler("User"));
    },
    [refetch, addUsersRole, deleteUsersRole]
  );

  const roleControls = useMemo(
    () => (
      <>
        <Button
          loading={isLoading || isFetching || isAddLoading || isDeleteLoading}
          type="submit"
          size="sm"
          invert
          bold
          onClick={() => onUpdateUsersRole("add")}
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp; Add role
        </Button>
        <Button
          loading={isLoading || isFetching || isAddLoading || isDeleteLoading}
          type="submit"
          size="sm"
          invert
          bold
          onClick={() => onUpdateUsersRole("delete")}
        >
          <FontAwesomeIcon icon={faMinus} />
          &nbsp; Remove role
        </Button>
      </>
    ),
    [isLoading, isFetching, isAddLoading, isDeleteLoading, onUpdateUsersRole]
  );

  return (
    <section className="roles__container">
      <form
        ref={formRef}
        className="roles__select"
        onSubmit={(e) => e.preventDefault()}
      >
        <Card<IUser[] | undefined>
          data={members}
          title="Set User Roles"
          elements={SelectUsersElements}
          controls={roleControls}
        />
      </form>
      <Table
        loading={isLoading || isFetching || isAddLoading || isDeleteLoading}
        className="roles__users-list"
        data={members}
        Row={UserRow}
        headerTags={["Name", "Role"]}
        caption={"Organization Users"}
      />
    </section>
  );
};

export default Roles;
