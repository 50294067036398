import TextField from "components/TextField/TextField";

export const NewTeamElements = (myUser: IUser) => () => {
  return [
    {
      title: "Requirements",
      element:
        "Every team's name has to be unique and minimum 6 characters long." +
        "By creating it, you're the team's owner and manager at the same time by default." +
        "Owners have the right to modify and delete a team.",
    },
    {
      title: "Name",
      element: (
        <>
          <TextField nolabel name="name" placeholder="Team's name" />
          <input
            readOnly
            type="text"
            name="organizationId"
            hidden
            value={myUser.organizationId}
          />
          <input readOnly type="text" name="owner" hidden value={myUser._id} />
          <input
            readOnly
            type="text"
            name="managers[]"
            hidden
            value={myUser._id}
          />
          <input
            readOnly
            type="text"
            name="users[]"
            hidden
            value={myUser._id}
          />
        </>
      ),
    },
  ];
};
