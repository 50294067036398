import { faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import {
  useCreateTeamMutation,
  useGetTeamsQuery,
} from "features/teams/teamApi.slice";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import ErrorPanel from "layouts/Panels/ErrorPanel";
import React, { useRef } from "react";
import { mutationFailHandler } from "utils/requests";
import { NewTeamElements } from "./NewTeamElements";

type Props = {
  refetch: ReturnType<typeof useGetTeamsQuery>["refetch"];
};

const CreateTeam = ({ refetch }: Props) => {
  const [createTeam, { isLoading, isError }] = useCreateTeamMutation();
  const {
    data: myUser,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetMyUserQuery();
  const formRef = useRef<HTMLFormElement>(null);

  const onCreateTeamSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newTeam: ITeamCreate = {
      name: formData.get("name") as string,
      organizationId: myUser!.organizationId,
      owner: myUser!._id,
      managers: [myUser!._id],
      users: [myUser!._id],
    };
    await createTeam(newTeam).unwrap().catch(mutationFailHandler("Team"));
    refetch();
    formRef.current?.reset();
  };

  const NewTeamControls = (
    <Button
      type="submit"
      size="sm"
      invert
      bold
      loading={isUserFetching || isLoading || isUserLoading}
      disabled={isUserFetching || isLoading || isUserLoading}
    >
      <FontAwesomeIcon icon={faUsersLine} />
      &nbsp; Create team
    </Button>
  );

  if (isError) return <ErrorPanel />;
  return (
    <form
      ref={formRef}
      className="teams__create-list"
      onSubmit={onCreateTeamSubmit}
    >
      <Card
        isLoading={isUserFetching || isLoading || isUserLoading}
        title="Create new team"
        elements={NewTeamElements(myUser ?? ({} as IUser))}
        controls={NewTeamControls}
      />
    </form>
  );
};

export default CreateTeam;
