import { apiSlice } from "app/api/api.slice";
import { API_VERSION } from "utils/constants";

// const ticketsAdapter = createEntityAdapter<ITicket>({
//   // Assume IDs are stored in a field other than `ticket.id`
//   selectId: (ticket) => ticket._id,
// });

// const initialState = ticketsAdapter.getInitialState();

export const ticketsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTicketById: builder.query<ITicket, ITicket["_id"]>({
      query: (ticketId) => ({
        url: `/api/${API_VERSION}/tickets/${ticketId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "Ticket", id }],
    }),
    getTickets: builder.query<ITicket[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/tickets/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({ type: "Ticket" as const, id })),
              { type: "Ticket", id: "TICKET_LIST" },
            ]
          : [{ type: "Ticket", id: "TICKET_LIST" }],
    }),
    createTicket: builder.mutation<ITicket, ITicketCreate>({
      query: (ticket: ITicketCreate) => ({
        url: `/api/${API_VERSION}/tickets/`,
        method: "POST",
        body: ticket,
      }),
      invalidatesTags: () => [{ type: "Ticket", id: "TICKET_LIST" }],
    }),
    updateTicketById: builder.mutation<ITicket, ITicketUpdate>({
      query: (update) => ({
        url: `/api/${API_VERSION}/tickets/${update._id}`,
        method: "PUT",
        body: update,
      }),
      invalidatesTags: (_result, _error, { _id }) => [
        { type: "Ticket", id: "TICKET_LIST" },
        { type: "Ticket", id: _id },
      ],
    }),
    createCommentByTicketId: builder.mutation<ITicket, ICommentCreate>({
      query: ({ ticketId, comment }) => ({
        url: `/api/${API_VERSION}/tickets/${ticketId}/comments`,
        method: "POST",
        body: comment,
      }),
      invalidatesTags: (_result, _error, { ticketId }) => [
        { type: "Ticket", id: "TICKET_LIST" },
        { type: "Ticket", id: ticketId },
      ],
    }),
    deleteCommentByTicketId: builder.mutation<ITicket, ICommentDelete>({
      query: ({ ticketId, commentId }) => ({
        url: `/api/${API_VERSION}/tickets/${ticketId}/comments`,
        method: "DELETE",
        body: { commentId },
      }),
      invalidatesTags: (_result, _error, { ticketId }) => [
        { type: "Ticket", id: ticketId },
      ],
    }),
    createAttachmentByTicketId: builder.mutation<ITicket, IAttachmentCreate>({
      query: ({ ticketId, attachment }) => ({
        url: `/api/${API_VERSION}/tickets/${ticketId}/attachments`,
        method: "POST",
        body: attachment,
      }),
      invalidatesTags: (_result, _error, { ticketId }) => [
        { type: "Ticket", id: "TICKET_LIST" },
        { type: "Ticket", id: ticketId },
      ],
    }),
    deleteTicketById: builder.mutation<any, ITicket["_id"]>({
      query: (ticketId: ITicket["_id"]) => ({
        url: `/api/${API_VERSION}/tickets/${ticketId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, ticketId) => [
        { type: "Ticket", id: "TICKET_LIST" },
        { type: "Ticket", id: ticketId },
      ],
    }),
  }),
});

export const {
  useGetTicketByIdQuery,
  useGetTicketsQuery,
  useCreateTicketMutation,
  useDeleteTicketByIdMutation,
  useUpdateTicketByIdMutation,
  useCreateCommentByTicketIdMutation,
  useDeleteCommentByTicketIdMutation,
} = ticketsApiSlice;

// export const selectTicketsResult = ticketsApiSlice.endpoints.getTickets.select();

// const selectTicketsData = createSelector(
//   selectTicketsResult,
//   (ticketsResult) => ticketsResult.data // normalized state object with ids & entities
// );

// export const {
//   selectAll: selectAllTickets,
//   selectById: selectTicketById,
//   selectIds: selectTicketIds,
//   // Pass in a selector that returns the tickets slice of state
// } = ticketsAdapter.getSelectors<RootState[apiSlice.reducerPath]>(
//   (state) => selectTicketsData(state[apiSlice.reducerPath]) ?? initialState
// );

// export const selectCurrentTicket = (state: RootState) => state.auth.ticket;
