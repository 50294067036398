import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { data, options } from "./chart.config";
import "./Chart.scss";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  type: string;
  title: string;
  labels: string[];
  rawData: number[];
  colors: string[];
  legendOn?: boolean;
};

const TicketChart = ({
  type,
  title,
  labels,
  rawData,
  colors,
  legendOn = false,
}: Props) => {
  const ChartComponent =
    type === "bar" ? Bar : type === "doughnut" ? Doughnut : Line;
  return (
    <div className="ticket-chart-container">
      <div className="ticket-chart__legend">{title}</div>
      <div className="ticket-chart__canvas">
        <ChartComponent
          style={{ width: "100%", height: "100%" }}
          data={data(labels, "All my projects", rawData, colors)}
          options={options(title, legendOn)}
        />
      </div>
    </div>
  );
};

export default TicketChart;
