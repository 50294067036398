import { skipToken } from "@reduxjs/toolkit/dist/query";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { useGetTicketByIdQuery } from "features/tickets/ticketApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import { Navigate, useParams } from "react-router-dom";
import { TicketDataElements } from "./Card/TicketData";
import TicketComments from "./Comments/Comments";
import TicketHistory from "./History/History";
import "./TicketById.scss";
import TabGroup from "components/TabGroup/TabGroup";
// import TicketAttachments from "./Attachments/Attachments";

type Props = {};

const TicketById = (props: Props) => {
  const params = useParams();
  const ticketId = params.ticketId;
  const {
    data: ticket,
    isLoading,
    isFetching,
    isError,
    isUninitialized,
  } = useGetTicketByIdQuery(ticketId || skipToken);

  if (!ticketId || !ticket) return <Navigate to={"/tickets"} />;
  const controls = (
    <>
      <Button size="sm" invert bold to={"/tickets"}>
        Back to list
      </Button>
      <Button size="sm" invert bold to={`/tickets/edit/${ticket._id}`}>
        Edit ticket
      </Button>
    </>
  );

  if (isLoading || isFetching || isUninitialized || !ticketId || !ticket)
    return <Loader />;
  if (isError) return <Error />;
  return (
    <PageContainer className="ticket-grid__container">
      <div className={"ticket-grid__element ticket__details"}>
        <Card<ITicket>
          title={`Ticket issue: ${ticket.name}`}
          data={ticket}
          elements={TicketDataElements}
          controls={controls}
        />
      </div>
      <TabGroup
        tabNames={[
          "Comments",
          "History",
          //  "Attachments"
        ]}
        tabs={[
          <TicketComments comments={ticket.comments} ticketId={ticket._id} />,
          <TicketHistory
            history={ticket.history}
            ticketUpdatedAt={ticket.updatedAt}
          />,
          // <TicketAttachments attachments={ticket.attachments} />,
        ]}
      />
    </PageContainer>
  );
};

export default TicketById;
