import React from "react";
import "./Select.scss";

type SelectProps<TData> = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  list: TData[];
  optionValue: keyof TData;
  optionText: keyof TData;
};

const Select = function <TData>({
  list,
  optionValue,
  optionText,
  ...props
}: SelectProps<TData>) {
  const options =
    !list || !list.length
      ? null
      : list
          .map((element) => (
            <option
              key={String(element[optionValue])}
              value={String(element[optionValue])}
            >
              {String(element[optionText])}
            </option>
          ))
          // disabled and hidden plus empty string value forces the user
          // to select an option
          .concat(
            <option key={-1} value="" disabled hidden defaultValue={""}>
              Not selected
            </option>
          );
  return (
    <select
      required
      name={props.name}
      className={`select ${props.className ?? ""}`}
      {...props}
    >
      {options}
    </select>
  );
};

export default Select;
