import { faSkull, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import { format } from "date-fns";
import { useGetUserByIdQuery } from "features/users/userApi.slice";

type Props = {
  content: IComment;
  onRowClicked: (data: any) => any;
};

export function CommentsRow({ content: comment, onRowClicked }: Props) {
  const {
    data: commenter,
    isFetching,
    isLoading,
    isError,
    isUninitialized,
  } = useGetUserByIdQuery(comment.commenterId || skipToken);

  if (isFetching || isLoading)
    return (
      <tr>
        <th colSpan={3}>
          Loading... <FontAwesomeIcon icon={faSpinner} spin />
        </th>
      </tr>
    );
  if (isError || isUninitialized) {
    return (
      <tr>
        <th colSpan={3}>
          Something went wrong... <FontAwesomeIcon icon={faSkull} spin />
        </th>
      </tr>
    );
  }
  return (
    <tr className="comment-item">
      <th className="comment-item__commenter">{commenter?.username}</th>
      <th className="comment-item__message" colSpan={2}>
        {comment.message}
      </th>
      <th className="comment-item__createdAt">
        {format(new Date(comment?.createdAt ?? 0), "MMMM dd, yyyy")}
      </th>
      <th className="comment-item__controls">
        <FlexCenter>
          <Button
            onClick={async () => await onRowClicked(comment._id)}
            invert
            title="Delete this team"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </FlexCenter>
      </th>
    </tr>
  );
}
