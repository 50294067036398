import { apiSlice } from "app/api/api.slice";
import { API_VERSION } from "utils/constants";

// const projectsAdapter = createEntityAdapter<IProject>({
//   // Assume IDs are stored in a field other than `project.id`
//   selectId: (project) => project._id,
// });

// const initialState = projectsAdapter.getInitialState();

export const projectsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectById: builder.query<IProject, IProject["_id"]>({
      query: (projectId) => ({
        url: `/api/${API_VERSION}/projects/${projectId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),
    getProjects: builder.query<IProject[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/projects/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "Project" as const,
                id,
              })),
              { type: "Project", id: "PROJECT_LIST" },
            ]
          : [{ type: "Project", id: "PROJECT_LIST" }],
    }),
    createProject: builder.mutation<IProject, IProjectCreate>({
      query: (project: IProjectCreate) => ({
        url: `/api/${API_VERSION}/projects/`,
        method: "POST",
        body: project,
      }),
      invalidatesTags: () => [{ type: "Project", id: "PROJECT_LIST" }],
    }),
    updateProjectById: builder.mutation<IProject, IProjectUpdate>({
      query: ({ id, update }) => ({
        url: `/api/${API_VERSION}/projects/${id}`,
        method: "PUT",
        body: update,
      }),
      invalidatesTags: () => [{ type: "Project", id: "PROJECT_LIST" }],
    }),
    deleteProjectById: builder.mutation<any, IProject["_id"]>({
      query: (projectId: IProject["_id"]) => ({
        url: `/api/${API_VERSION}/projects/${projectId}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "Project", id: "PROJECT_LIST" }],
    }),
    createVersionByProjectId: builder.mutation<IProject, IVersionCreate>({
      query: ({ id, update }) => ({
        url: `/api/${API_VERSION}/projects/${id}/version`,
        method: "POST",
        body: update,
      }),
      invalidatesTags: () => [{ type: "Project", id: "PROJECT_LIST" }],
    }),
    updateVersionByProjectId: builder.mutation<IProject, IVersionUpdate>({
      query: ({ projectId, versionId, updates }) => ({
        url: `/api/${API_VERSION}/projects/${projectId}/version/${versionId}`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: "Project", id: "PROJECT_LIST" },
        { type: "Project", id: projectId },
      ],
    }),
    deleteVersionByProjectId: builder.mutation<void, IVersionDelete>({
      query: ({ projectId, versionId }) => ({
        url: `/api/${API_VERSION}/projects/${projectId}/version/${versionId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { projectId }) => [
        { type: "Project", id: "PROJECT_LIST" },
        { type: "Project", id: projectId },
      ],
    }),
  }),
});

export const {
  useGetProjectByIdQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectByIdMutation,
  useUpdateProjectByIdMutation,
  useCreateVersionByProjectIdMutation,
  useUpdateVersionByProjectIdMutation,
  useDeleteVersionByProjectIdMutation,
} = projectsApiSlice;
