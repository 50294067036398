import {
  faFloppyDisk,
  faTrash,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import TextField from "components/TextField/TextField";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import "./TeamRow.scss";

type Props<TAction> = {
  content: ITeam;
  onRowClicked: (data: TAction) => any;
};

type TTeamAction = {
  teamId: string;
  action: "delete" | "select" | "saveName";
  data?: string;
};

const TeamRow = ({ content: team, onRowClicked }: Props<TTeamAction>) => {
  const teamNameRef = useRef<HTMLInputElement>(null);
  const [nameFieldClicked, setNameFieldClicked] = useState<boolean>(false);
  const [unsaved, setUnsaved] = useState<boolean>(false);
  const formattedDate = team?.createdAt
    ? format(new Date(team?.createdAt), "MMMM dd, yyyy")
    : "";

  useEffect(() => {
    if (nameFieldClicked) {
      teamNameRef.current?.focus();
      console.log("teamNameRef:", teamNameRef.current?.name);
    }
  }, [nameFieldClicked]);

  const onSaveNameClick = async () => {
    console.log("save name click");
    if (teamNameRef.current?.value && teamNameRef.current?.value.length > 0) {
      console.log("update attempt");
      setNameFieldClicked(false);
      await onRowClicked({
        action: "saveName",
        teamId: team._id,
        data: teamNameRef.current.value,
      });
      setUnsaved(false);
    } else {
      console.log("checking validity");
      teamNameRef.current?.checkValidity();
    }
  };

  return (
    <tr className="team-item">
      <th
        className="team-item__name"
        onClick={() => {
          setNameFieldClicked(true);
        }}
      >
        <TextField
          nolabel
          className={!nameFieldClicked ? `team-item__name-field` : ""}
          ref={teamNameRef}
          name={team.name}
          defaultValue={team.name}
          readOnly={!nameFieldClicked}
          onChange={() => setUnsaved(true)}
          onBlur={() => {
            setNameFieldClicked(false);
          }}
          required
          minLength={6}
        />
      </th>
      <th className="team-item__managers">{formattedDate}</th>
      <th className="team-item__controls">
        <span>
          <Button
            onClick={onSaveNameClick}
            invert
            title="Save New Name"
            style={{
              visibility: nameFieldClicked || unsaved ? "visible" : "hidden",
            }}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </Button>
          <Button
            onClick={async () =>
              await onRowClicked({ action: "select", teamId: team._id })
            }
            invert
            title="Select users view"
          >
            <FontAwesomeIcon icon={faUsersCog} />
          </Button>
          <Button
            onClick={async () =>
              await onRowClicked({ action: "delete", teamId: team._id })
            }
            invert
            title="Delete this team"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </span>
      </th>
    </tr>
  );
};

export default TeamRow;
