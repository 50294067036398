import Button from "components/Button/Button";
import { FlexCenter } from "components/FlexBox/FlexCenter";
import { Navigate, useParams } from "react-router-dom";
import CreateOrganizationForm from "./Forms/CreateOrganizationForm";
import JoinRequestForm from "./Forms/JoinRequestForm";
import "./Membership.scss";
import { useEffect, useState } from "react";
import PublicPage from "layouts/PublicPage/PublicPage";
import { useGetMembershipByUserIdQuery } from "features/organizations/organizationApi.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Loader from "layouts/Loaders/Loader";

type Props = {};

const Membership = (props: Props) => {
  const params = useParams();
  const userId = params.userId;
  const [operation, setOperation] = useState<TOperationType>(null);
  const { data, isLoading, isFetching } = useGetMembershipByUserIdQuery(
    userId ?? skipToken
  );
  console.log("data :>> ", data);
  useEffect(() => {
    if (data && data?.organizationId) {
      setOperation("pending");
    }
  }, [data, setOperation]);

  if (!userId) return <Navigate to={"/"} replace />;

  let content;
  switch (operation) {
    case "pending":
      content = (
        <div className="membership-form">
          <div className="membership-form__submit-success">
            <span className="membership__status-alert">
              Organization request approval pending
            </span>
            <FlexCenter>
              Your organization administrator supervisor must approve your
              request first, before being able to sign-in
            </FlexCenter>
          </div>
        </div>
      );
      break;
    case "create":
      content = (
        <div className="membership-form">
          <div className="membership-form__submit-success">
            <span className="membership__status-alert">
              Organization successfully created!
            </span>
            <FlexCenter>
              You can now sign-in to your account and start using Bugoscope.
            </FlexCenter>
          </div>
        </div>
      );
      break;
    case "request":
      content = (
        <div className="membership-form">
          <div className="membership-form__submit-success">
            <span className="membership__status-alert">
              Membership request successfully sent!
            </span>
            <FlexCenter>
              As soon as your organization supervisor approves it, you will be
              able to use Bugoscope to work on their projects.
            </FlexCenter>
          </div>
        </div>
      );
      break;
    default:
      content = (
        <>
          <hr style={{ width: "100%" }} />
          <div className="membership__legend">
            Join an existing organization by selecting from the list.
          </div>
          <JoinRequestForm userId={userId} setOperation={setOperation} />
          <hr style={{ width: "100%" }} />
          <div className="membership__legend">
            Or create a new organization. When you are the creator,
            automatically you become the organization's administrator with full
            rights.
          </div>
          <CreateOrganizationForm
            directorId={userId}
            setOperation={setOperation}
          />
        </>
      );
      break;
  }
  if (isLoading || isFetching) return <Loader />;
  return (
    <PublicPage title="Organization membership">
      {content}
      <FlexCenter>
        <Button size="sm" to={"/login"} invert>
          Sign-in
        </Button>
        <Button size="sm" to={"/"} invert>
          Welcome page
        </Button>
      </FlexCenter>
    </PublicPage>
  );
};

export default Membership;
