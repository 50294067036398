import { faUserMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Table from "components/Table/Table";
import {
  useAcceptOrganizationMembersMutation,
  useDeleteMembershipRequestsMutation,
  useGetMyOrganizationQuery,
} from "features/organizations/organizationApi.slice";
import {
  useUpdateAddUsersRoleMutation,
  useUpdateDeleteUsersRoleMutation,
} from "features/users/userApi.slice";
import { useRef } from "react";
import { toast } from "react-toastify";
import { getFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";
import { EUserRoles } from "utils/roles";
import { SelectRequestsElements } from "./Forms/SelectRequestsElements";
import RequestorRow from "./Rows/RequestorRow";

type Props = {
  requests: IUser[];
  members: IUser[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: ReturnType<typeof useGetMyOrganizationQuery>[0];
};

const Requests = ({
  members,
  requests,
  isFetching,
  isLoading,
  refetch,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [removeRequests, { isLoading: isRemoveRequestLoading }] =
    useDeleteMembershipRequestsMutation();
  const [acceptRequests, { isLoading: isAcceptRequestLoading }] =
    useAcceptOrganizationMembersMutation();

  const [addUsersRole, { isLoading: isAddRoleLoading }] =
    useUpdateAddUsersRoleMutation();
  const [deleteUsersRole, { isLoading: isRemoveRoleLoading }] =
    useUpdateDeleteUsersRoleMutation();

  const onToggleUserClick = async (action: TUserMemberAction) => {
    const { type, userId } = action;
    const update = { role: EUserRoles.Disabled, userIds: [userId] };
    const request =
      type === "disable" ? addUsersRole(update) : deleteUsersRole(update);
    const response = await request
      .unwrap()
      .then((response) => {
        refetch();
        const status = type === "disable" ? "disabled" : "enabled";
        toast.success(`User member status: ${status}`);
        return response;
      })
      .catch(
        mutationFailHandler("User", (msg, error) => {
          console.log("error :>> ", error);
          toast.error(`There was an error updating membership status: ${msg}`);
        })
      );
    console.log("response :>> ", response);
  };

  const onUpdateRequests = async (operation: "accept" | "remove") => {
    if (!formRef.current) return;
    const update = getFormData<{ users: string[] }>(formRef.current);
    const request =
      operation === "accept" ? acceptRequests(update) : removeRequests();
    const response = await request
      .unwrap()
      .then((response) => {
        refetch();
        const successMsg =
          operation === "accept"
            ? "Users memberships accepted!"
            : "Requests cleared!";
        toast.success(successMsg);
        return response;
      })
      .catch(
        mutationFailHandler("User", (msg, error) => {
          console.log("error :>> ", error);
          toast.error(`There was an error updating the request: ${msg}`);
        })
      );
    console.log("response :>> ", response);
  };

  const requestsControls = (
    <>
      <Button
        loading={isAcceptRequestLoading || isRemoveRequestLoading}
        type="submit"
        size="sm"
        invert
        bold
        onClick={() => onUpdateRequests("accept")}
      >
        <FontAwesomeIcon icon={faUserPlus} />
        &nbsp; Accept
      </Button>
      <Button
        loading={isAcceptRequestLoading || isRemoveRequestLoading}
        type="submit"
        size="sm"
        invert
        bold
        onClick={() => onUpdateRequests("remove")}
      >
        <FontAwesomeIcon icon={faUserMinus} />
        &nbsp; Clear all
      </Button>
    </>
  );

  return (
    <section className="request__container">
      <form
        ref={formRef}
        className="roles__select"
        onSubmit={(e) => e.preventDefault()}
      >
        <Card<IUser[] | undefined>
          data={requests}
          title="Manage membership requests"
          elements={SelectRequestsElements}
          controls={requestsControls}
        />
      </form>
      <Table
        loading={isFetching || isLoading}
        caption="Members"
        headerTags={[{ span: 3, text: "Username" }, "Membership Toggle"]}
        Row={RequestorRow(isAddRoleLoading || isRemoveRoleLoading)}
        className="request__users-list"
        data={members}
        onClicked={onToggleUserClick}
      />
    </section>
  );
};

export default Requests;
