import React from "react";
import { capitalize } from "utils/stringManip";
import "./TextField.scss";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  txtSize?: "lg" | "md" | "sm";
  nolabel?: boolean;
  labelClass?: string;
  optional?: boolean;
};

const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      nolabel = false,
      labelClass,
      txtSize: size,
      optional = false,
      ...props
    }: Props,
    ref
  ) => {
    const txtSize =
      size === "sm" ? "txt-small" : size === "lg" ? "txt-large" : "txt-medium";
    return (
      <label
        htmlFor={props.name}
        className={`textfield ${txtSize} ${labelClass}`}
      >
        {!nolabel && `${capitalize(props.name)}:`}
        <input
          onChange={props.onChange}
          ref={ref}
          type="text"
          id={props.name}
          name={props.name}
          placeholder={capitalize(props.name)}
          required={optional ? undefined : true}
          autoComplete="off"
          {...props}
        />
      </label>
    );
  }
);

export default TextField;
