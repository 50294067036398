import Button from "components/Button/Button";
import Table from "components/Table/Table";
import TextField from "components/TextField/TextField";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import "./Comments.scss";
import {
  useCreateCommentByTicketIdMutation,
  useDeleteCommentByTicketIdMutation,
} from "features/tickets/ticketApi.slice";
import { mutationFailHandler } from "utils/requests";
import { CommentsRow } from "./CommentsRow";
import { toast } from "react-toastify";

type Props = {
  className?: string;
  ticketId: ITicket["_id"];
  comments: ITicket["comments"];
};

const TicketComments = ({ className, ticketId, comments }: Props) => {
  const [createComment, { isLoading: isCreateComment }] =
    useCreateCommentByTicketIdMutation();

  const [deleteComment, { isLoading: isDeleteComment }] =
    useDeleteCommentByTicketIdMutation();
  const {
    data: myUser,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetMyUserQuery();

  const onCommentSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newComment = {
      ticketId,
      comment: {
        message: String(formData.get("message")),
        commenterId: String(myUser?._id),
      },
    };
    await createComment(newComment)
      .unwrap()
      .catch(mutationFailHandler("Ticket"));
  };

  const extraControls = () => (
    <form className="comments__create" onSubmit={onCommentSubmit}>
      <Button
        className="comment__add"
        size="sm"
        type="submit"
        loading={isLoading}
      >
        Add comment
      </Button>
      <TextField
        txtSize={"sm"}
        labelClass="comment__message"
        name="message"
        nolabel
      />
      <TextField
        className="comment__commenter"
        nolabel
        name="commenterId"
        hidden
        defaultValue={myUser?._id}
      />
    </form>
  );

  const onCommentDeleteClick = async (commentId: string) => {
    const deleteParams = { ticketId, commentId };
    await deleteComment(deleteParams)
      .unwrap()
      .then((response) => {
        toast.warn("Comment deleted!");
        return response;
      })
      .catch((error) => {
        toast.error(`Error deleting comment:${error.data.message}`);
      });
  };

  const isLoading =
    isCreateComment || isDeleteComment || isUserFetching || isUserLoading;
  return (
    <div className={`comments__container ${className}`}>
      <Table
        nocaption
        loading={isLoading}
        caption="Comments:"
        headerTags={[
          "Commenter",
          { span: 2, text: "Comment" },
          "Date",
          "Controls",
        ]}
        data={comments}
        Row={CommentsRow}
        extraControls={extraControls()}
        onClicked={onCommentDeleteClick}
      />
    </div>
  );
};

export default TicketComments;
