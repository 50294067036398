import { apiSlice } from "app/api/api.slice";
import { API_VERSION } from "utils/constants";

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<IOrganization[], void>({
      query: () => ({
        url: `/organizations/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "Organization" as const,
                id,
              })),
              { type: "Organization", id: "ORGANIZATION_LIST" },
            ]
          : [{ type: "Organization", id: "ORGANIZATION_LIST" }],
    }),
    getMembershipByUserId: builder.query<
      { message: string; organizationId?: string },
      IUser["_id"]
    >({
      query: (userId) => ({
        url: `/organizations/requests/${userId}`,
        method: "GET",
      }),
    }),
    getMyOrganization: builder.query<IOrganizationPopulated, void>({
      query: () => ({
        url: `/api/${API_VERSION}/organizations/`,
        method: "GET",
      }),
      providesTags: (result) => [
        { type: "Organization", id: "ORGANIZATION_LIST" },
      ],
    }),
    createOrganization: builder.mutation<IOrganization, IOrganizationCreate>({
      query: (organization: IOrganizationCreate) => ({
        url: `/organizations/`,
        method: "POST",
        body: organization,
      }),
      invalidatesTags: () => [
        { type: "Organization", id: "ORGANIZATION_LIST" },
      ],
    }),
    createMembershipRequest: builder.mutation<void, IMembershipRequest>({
      query: ({ organizationId, userId }: IMembershipRequest) => ({
        url: `/organizations/${organizationId}/requests`,
        method: "POST",
        body: { userId },
      }),
      invalidatesTags: () => [
        { type: "Organization", id: "ORGANIZATION_LIST" },
      ],
    }),
    getMembershipRequests: builder.query<IUser[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/organizations/requests`,
        method: "GET",
      }),
    }),
    acceptOrganizationMembers: builder.mutation<void, { users: string[] }>({
      query: (update) => ({
        url: `/api/${API_VERSION}/organizations/requests`,
        method: "PUT",
        body: update,
      }),
    }),
    deleteMembershipRequests: builder.mutation<void, void>({
      query: () => ({
        url: `/api/${API_VERSION}/organizations/requests`,
        method: "DELETE",
      }),
    }),
    getOrganizationMembers: builder.query<IUser[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/organizations/members`,
        method: "GET",
      }),
    }),
    updateOrganization: builder.mutation<IOrganization, IOrganizationUpdate>({
      query: ({ _id, name, directorId }) => ({
        url: `/api/${API_VERSION}/organizations/${_id}`,
        method: "PUT",
        body: { name, directorId },
      }),
      invalidatesTags: () => [
        { type: "Organization", id: "ORGANIZATION_LIST" },
      ],
    }),
    deleteOrganization: builder.mutation<any, IOrganization["_id"]>({
      query: (organizationId: IOrganization["_id"]) => ({
        url: `/api/${API_VERSION}/organizations/${organizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Organization", id: "ORGANIZATION_LIST" },
      ],
    }),
  }),
});

export const {
  useGetMyOrganizationQuery,
  useGetOrganizationMembersQuery,
  useGetMembershipByUserIdQuery,
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
  useCreateMembershipRequestMutation,
  useGetMembershipRequestsQuery,
  useDeleteMembershipRequestsMutation,
  useAcceptOrganizationMembersMutation,
} = organizationsApiSlice;
