import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { useCreateTicketMutation } from "features/tickets/ticketApi.slice";
import Error from "layouts/Error/Error";
import PageContainer from "layouts/PageContainer/PageContainer";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { getTicketFormData } from "utils/forms";
import { mutationFailHandler } from "utils/requests";
import { NewTicketFormElements } from "../TicketById/Card/NewTicketElements";
import "./TicketNew.scss";

type Props = {};

const TicketNew = (props: Props) => {
  const [createTicket, { isLoading, isError }] = useCreateTicketMutation();

  const onCreateTicketSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    if (e.currentTarget.reportValidity()) {
      const ticket = getTicketFormData(e.currentTarget);
      await createTicket(ticket)
        .unwrap()
        .then((response) => {
          toast.success("Ticket created success!");
          // Reset form
          e.currentTarget?.reset();
          return response;
        })
        .catch((error) => {
          toast.error(`Error while creating ticket:${error.data.message}`);
          mutationFailHandler("Ticket")(error);
        });
    }
  };

  const controls = useMemo(
    () => (
      <>
        <Button type="submit" size="sm" invert bold loading={isLoading}>
          Create ticket
        </Button>
        <Button to={`/tickets`} size="sm" invert bold>
          Tickets list
        </Button>
      </>
    ),
    [isLoading]
  );

  if (isError) return <Error />;
  return (
    <PageContainer className="ticket-new__container">
      <form onSubmit={onCreateTicketSubmit} className="ticket-new__form">
        <Card<ITicket>
          title={`Create ticket:`}
          elements={NewTicketFormElements}
          controls={controls}
        />
      </form>
    </PageContainer>
  );
};

export default TicketNew;
