import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import { useGetOrganizationMembersQuery } from "features/organizations/organizationApi.slice";
import { useGetProjectsQuery } from "features/projects/projectApi.slice";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import { useState } from "react";
import {
  priorities,
  severities,
  statuses,
  tTypes,
} from "utils/ticket/settings";

export const NewTicketFormElements = () => {
  const { data: creator } = useGetMyUserQuery();
  const { data: assigneeUsers } = useGetOrganizationMembersQuery();
  const { data: projects } = useGetProjectsQuery();
  const [versions, setVersions] = useState<IVersion[] | null>(null);

  const onChangeProject: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const project = projects?.find((project) => project._id === e.target.value);
    setVersions(project?.version ?? null);
  };
  return [
    {
      title: "Name",
      element: <TextField nolabel name="name" />,
    },
    {
      title: "Details",
      element: <TextField nolabel name="details" />,
    },
    {
      title: "Creator",
      element: !creator?._id ? (
        ""
      ) : (
        <>
          <TextField nolabel value={creator?.username} readOnly />
          <TextField
            nolabel
            hidden
            name="creatorId"
            value={creator?._id}
            readOnly
          />
        </>
      ),
    },
    {
      title: "Assigned to",
      element: !assigneeUsers ? (
        "No users available"
      ) : (
        <Select<IUser>
          name="assigneeId"
          list={assigneeUsers}
          optionValue={"_id"}
          optionText={"username"}
          defaultValue={""}
        />
      ),
      // <select name="assigned" required>
      //   {!assigneeUsers
      //     ? null
      //     : assigneeUsers.map((assigned) => (
      //         <option defaultValue={assigned._id}>{assigned.username}</option>
      //       ))}
      // </select>
    },
    {
      title: "Project",
      element: !projects ? (
        "No projects available"
      ) : (
        <Select<IProject>
          name="projectId"
          list={projects}
          optionValue={"_id"}
          optionText={"name"}
          onChange={onChangeProject}
          defaultValue={""}
        />
      ),
      // <select name="project" required>
      //   {!projects
      //     ? null
      //     : projects.map((project) => (
      //         <option defaultValue={project._id}>{project.name}</option>
      //       ))}
      // </select>
    },
    {
      title: "Version",
      element: !versions ? (
        "No versions available"
      ) : (
        <Select<IVersion>
          name="versionId"
          list={versions}
          optionValue={"_id"}
          optionText={"name"}
          defaultValue={""}
        />
      ),
      // <select name="project" required>
      //   {!projects
      //     ? null
      //     : projects.map((project) => (
      //         <option defaultValue={project._id}>{project.name}</option>
      //       ))}
      // </select>
    },
    {
      title: "Ticket type",
      element: (
        <Select<[string, string]>
          name="type"
          list={tTypes}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={""}
        />
        // <select name="priority" required>
        //   {priorityLevels.map((level, index) => (
        //     <option value={index}>{level}</option>
        //   ))}
        // </select>
      ),
    },
    {
      title: "Priority",
      element: (
        <Select<[string, string]>
          name="priority"
          list={priorities}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={""}
        />
        // <select name="priority" required>
        //   {priorityLevels.map((level, index) => (
        //     <option value={index}>{level}</option>
        //   ))}
        // </select>
      ),
    },
    {
      title: "Severity",
      element: (
        <Select<[string, string]>
          name="severity"
          list={severities}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={""}
        />
        // <select name="severity" required>
        //   {severityLevels.map((level, index) => (
        //     <option value={index}>{level}</option>
        //   ))}
        // </select>
      ),
    },
    {
      title: "Status",
      element: (
        <Select<[string, string]>
          name="status"
          list={statuses}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={""}
        />
        // <select name="status" required>
        //   {statusOptions.map((level, index) => (
        //     <option defaultValue={index}>{level}</option>
        //   ))}
        // </select>
      ),
    },
  ];
};
