import Button from "components/Button/Button";
import Card from "components/Card/Card";
import TextField from "components/TextField/TextField";
import {
  useGetMyUserQuery,
  useUpdateUserPasswordMutation,
} from "features/users/userApi.slice";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import { UserDataElements } from "pages/Profile/Card/UserData";
import "./Profile.scss";
import { useMemo } from "react";
import { toast } from "react-toastify";

type Props = {};

// username: string;
// password: string;
// roles: number[];
// myProjects: string[];
const PasswordElements = () => [
  {
    title: "Requirements:",
    element: (
      <span>
        Password must be minimum 6 characters and maximum 10 characters long
      </span>
    ),
  },
  {
    title: "Current password",
    element: (
      <TextField
        nolabel
        name="oldPassword"
        type="password"
        placeholder="********"
      />
    ),
  },
  {
    title: "New Password",
    element: (
      <TextField
        nolabel
        name="password"
        type="password"
        placeholder="********"
      />
    ),
  },
  {
    title: "Repeat new",
    element: (
      <TextField nolabel name="repeat" type="password" placeholder="********" />
    ),
  },
];

const Profile = (props: Props) => {
  const { data: user, isError, isLoading } = useGetMyUserQuery();
  const [updatePassword, { isLoading: isPasswordUpdateLoading }] =
    useUpdateUserPasswordMutation();

  const onSubmitUserUpdate: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    const editForm = new FormData(e.currentTarget);
    if (editForm.get("password") === editForm.get("repeat")) {
      editForm.delete("repeat");
      const update = Object.fromEntries(editForm) as {
        password: string;
        oldPassword: string;
      };
      const result = await updatePassword(update)
        .unwrap()
        .then((response) => {
          toast.success("Password updated!");
          return response;
        })
        .catch((err) => {
          toast.error("Update failed!");
          return err;
        });
      console.log(update);
      console.log("result :>> ", result);
    }
  };
  const controls = useMemo(
    () => (
      <Button
        type="submit"
        size="sm"
        invert
        bold
        loading={isPasswordUpdateLoading}
      >
        Update user profile
      </Button>
    ),
    [isPasswordUpdateLoading]
  );

  if (isError) return <Error />;
  if (isLoading) return <Loader />;
  return (
    <PageContainer>
      <div className="profile__container">
        <div className="profile__data">
          <Card
            title={"My Profile:"}
            elements={UserDataElements}
            data={user}
            controls={<></>}
          />
        </div>
        <form className="profile__password" onSubmit={onSubmitUserUpdate}>
          <Card
            title={"Update:"}
            elements={PasswordElements}
            controls={controls}
          />
        </form>
      </div>
    </PageContainer>
  );
};

export default Profile;
