import React, { useId, useState } from "react";
import "./TabGroup.scss";

type Props = {
  tabNames: string[];
  tabs: JSX.Element[];
};

const TabGroup: React.FC<Props> = ({ tabNames, tabs }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const id = useId();
  return (
    <div className="tabgroup__container">
      <div className="tabgroup__button_group">
        {tabNames.map((name, index) => (
          <button
            key={"tabgroup" + id + index}
            className={`${index === tabIndex ? "tabgroup__selected" : ""}`}
            type="button"
            onClick={() => setTabIndex(index)}
          >
            {name}
          </button>
        ))}
      </div>
      <hr />
      <div className="tabgroup__content">{tabs[tabIndex]}</div>
    </div>
  );
};

export default TabGroup;
