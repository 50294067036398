export const getFormDataObject = (form: HTMLFormElement) =>
  Object.fromEntries(new FormData(form).entries());

export const getTicketFormData = (form: HTMLFormElement) => {
  const unparsed = Object.fromEntries(new FormData(form)) as Record<
    keyof Partial<ITicket>,
    string
  >;

  return {
    ...unparsed,
    status: parseInt(unparsed.status),
    severity: parseInt(unparsed.severity),
    priority: parseInt(unparsed.priority),
    type: parseInt(unparsed.type),
  } as Partial<ITicket>;
};

export const getRegistrationFormData = (form: HTMLFormElement) => {
  return Object.fromEntries(new FormData(form)) as TUserRegister;
};

export const getFormData = <T>(form: HTMLFormElement) =>
  Object.fromEntries(new FormData(form)) as T;
