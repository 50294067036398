import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import {
  useGetTeamsQuery,
  useUpdateTeamByIdMutation,
} from "features/teams/teamApi.slice";
import { useGetUsersQuery } from "features/users/userApi.slice";
import ErrorPanel from "layouts/Panels/ErrorPanel";
import { FormEventHandler, useCallback, useRef } from "react";
import { mutationFailHandler } from "utils/requests";
import "./AddTeamUser.scss";
import { NewUsersElements } from "./NewUsersElements";

type Props = {
  isUsersLoading: boolean;
  refetchUsers: ReturnType<typeof useGetUsersQuery>["refetch"];
  refetchTeams: ReturnType<typeof useGetTeamsQuery>["refetch"];
  team: ITeam;
  users: IUser[];
};

const AddTeamUser = ({
  isUsersLoading,
  users,
  team,
  refetchTeams,
  refetchUsers,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [updateTeam, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateTeamByIdMutation();

  const onAddTeamUserSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const addUsers = (formData.getAll("users") || []) as string[];
    const newTeamUsers: ITeamUpdate = {
      id: team!._id,
      update: {
        users: team!.users.concat(addUsers),
      },
    };
    await updateTeam(newTeamUsers).unwrap().catch(mutationFailHandler("Team"));
    await refetchTeams();
    await refetchUsers();
    formRef.current?.reset();
  };

  const newUserCandidates = useCallback(
    () =>
      users
        ?.filter(({ _id }) => !team!.users.includes(_id))
        .map((user) => ({ id: user._id, username: user.username })),
    [team, users]
  );

  const AddToTeamControls = (
    <Button
      type="submit"
      size="sm"
      invert
      bold
      loading={isUsersLoading || isUpdateLoading}
    >
      <FontAwesomeIcon icon={faUserPlus} />
      &nbsp; Add to team
    </Button>
  );

  if (isUpdateError) return <ErrorPanel />;
  return (
    <form
      ref={formRef}
      className="teams__add-user"
      onSubmit={onAddTeamUserSubmit}
    >
      <Card<{ id: string; username: string }[] | undefined>
        isLoading={isUsersLoading || isUpdateLoading}
        title="Add users to team"
        data={newUserCandidates()}
        elements={NewUsersElements}
        controls={AddToTeamControls}
      />
    </form>
  );
};

export default AddTeamUser;
