import Select from "components/Select/Select";
import TextField from "components/TextField/TextField";
import { useGetOrganizationMembersQuery } from "features/organizations/organizationApi.slice";
import { useGetProjectsQuery } from "features/projects/projectApi.slice";
import { useGetMyUserQuery } from "features/users/userApi.slice";
import { useEffect, useState } from "react";
import {
  priorities,
  severities,
  statuses,
  tTypes,
} from "utils/ticket/settings";

export const EditTicketFormElements = (ticket: ITicket) => {
  const { data: creator } = useGetMyUserQuery();
  const { data: users } = useGetOrganizationMembersQuery();
  const { data: projects, isSuccess } = useGetProjectsQuery();
  const [versions, setVersions] = useState<IVersion[] | undefined>(undefined);
  const [project, setProject] = useState<IProject | undefined>(undefined);

  useEffect(() => {
    isSuccess &&
      setVersions(
        projects?.find((project) => project._id === ticket.projectId)?.version
      );
  }, [isSuccess, projects, ticket]);

  const onChangeProject: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const project = projects?.find((project) => project._id === e.target.value);
    setProject(project);
    setVersions(project?.version);
  };
  return [
    {
      title: "Name",
      element: (
        <>
          <TextField
            nolabel
            name="_id"
            defaultValue={ticket._id}
            hidden
            readOnly
          />
          <TextField nolabel name="name" defaultValue={ticket.name} />
        </>
      ),
    },
    {
      title: "Details",
      element: (
        <TextField nolabel name="details" defaultValue={ticket.details} />
      ),
    },
    {
      title: "Creator",
      element: !creator?._id ? (
        ""
      ) : (
        <>
          <TextField
            nolabel
            defaultValue={
              users?.find((u) => u._id === ticket.creatorId)?.username
            }
            readOnly
          />
          <TextField
            nolabel
            hidden
            name="creatorId"
            defaultValue={ticket.creatorId}
            readOnly
          />
        </>
      ),
    },
    {
      title: "Assigned to",
      element: !users ? (
        "No users available"
      ) : (
        <Select<IUser>
          name="assigneeId"
          list={users}
          optionValue={"_id"}
          optionText={"username"}
          defaultValue={ticket.assigneeId}
        />
      ),
    },
    {
      title: "Project",
      element: !projects ? (
        "No projects available"
      ) : (
        <Select<IProject>
          name="projectId"
          list={projects}
          optionValue={"_id"}
          optionText={"name"}
          onChange={onChangeProject}
          defaultValue={project?._id ?? ticket.projectId}
        />
      ),
    },
    {
      title: "Version",
      element: !versions ? (
        "No versions available"
      ) : (
        <Select<IVersion>
          name="versionId"
          list={versions}
          optionValue={"_id"}
          optionText={"name"}
          defaultValue={ticket.versionId}
        />
      ),
    },
    {
      title: "Ticket type",
      element: (
        <Select<[string, string]>
          name="type"
          list={tTypes}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={ticket.type}
        />
      ),
    },
    {
      title: "Priority",
      element: (
        <Select<[string, string]>
          name="priority"
          list={priorities}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={ticket.priority}
        />
      ),
    },
    {
      title: "Severity",
      element: (
        <Select<[string, string]>
          name="severity"
          list={severities}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={ticket.severity}
        />
      ),
    },
    {
      title: "Status",
      element: (
        <Select<[string, string]>
          name="status"
          list={statuses}
          optionValue={"0"}
          optionText={"1"}
          defaultValue={ticket.status}
        />
      ),
    },
  ];
};
