import React from "react";
import "./Notification.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {};

const Notification: React.FC<Props> = (props: Props) => {
  return <div className="notification">{props.children}</div>;
};

export default Notification;
