export const demoCredentials: Record<TDemoType, IAuthLogin> = {
  disabled: {
    username: "DemoDisabledUser",
    password: "DemoDisabledUser",
  },
  regular: {
    username: "DemoRegularUser",
    password: "DemoRegularUser",
  },
  manager: {
    username: "DemoManager",
    password: "DemoManager",
  },
  director: {
    username: "DemoDirector",
    password: "DemoDirector",
  },
};
