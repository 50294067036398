import {
  faClock,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTicketPriority,
  getTicketSeverity,
  getTicketStatus,
} from "utils/ticket/settings";
import "../../Tickets/Rows/TicketRow.scss";

type Props = {
  content: ITicket;
  onRowClicked: (data: any) => any;
};

const NewestTicketRow = ({ content: ticket, onRowClicked }: Props) => {
  const { level: priorityLevel, color } = getTicketPriority(ticket.priority);
  const { level: severityLevel, color: severityColor } = getTicketSeverity(
    ticket.severity
  );
  const { level: statusLevel } = getTicketStatus(ticket.status);
  return (
    <tr className="ticket-item" onClick={(e) => onRowClicked(ticket._id)}>
      <th className="ticket-item__name">{ticket.name}</th>
      <th className="ticket-item__status">{statusLevel}</th>
      <th className="ticket-item__priority">
        <span className="chip" style={{ backgroundColor: color }}>
          <FontAwesomeIcon icon={faClock} /> {priorityLevel}
        </span>
      </th>
      <th className="ticket-item__severity">
        <span style={{ backgroundColor: severityColor }} className="chip">
          <FontAwesomeIcon icon={faTriangleExclamation} /> {severityLevel}
        </span>
      </th>
    </tr>
  );
};

export default NewestTicketRow;
