import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {};

const ErrorPanel = (props: Props) => {
  return (
    <div className="error-panel__container ">
      Something went wrong...&nbsp;
      <FontAwesomeIcon icon={faXmark} />
    </div>
  );
};

export default ErrorPanel;
