import { useProject } from "hooks/useProject";
import Error from "layouts/Error/Error";
import Loader from "layouts/Loaders/Loader";
import PageContainer from "layouts/PageContainer/PageContainer";
import { useParams } from "react-router-dom";
import AddVersion from "./Edit/AddNewVersion";
import ProjectEdit from "./Edit/ProjectEdit";
import "./ProjectById.scss";
import VersionList from "./VersionList";

type Props = {};

const ProjectById = (props: Props) => {
  const params = useParams();

  const {
    refetch,
    isError,
    isLoading,
    project,
    teams,
    user,
    updateVersion,
    deleteVersion,
    deleteProject,
    updateProject,
    createVersion,
  } = useProject(params);

  if (isLoading) return <Loader />;
  if (isError || !project || !teams || !user) return <Error />;

  return (
    <PageContainer className="project-id__container">
      <ProjectEdit
        className="project-version__edit"
        project={project}
        user={user}
        teams={teams}
        updateProject={updateProject}
        deleteProject={deleteProject}
        refetch={refetch}
      />
      <AddVersion
        className="project-version__add"
        projectId={project._id}
        createVersion={createVersion}
        refetch={refetch}
      />
      <VersionList
        className="project-version__list"
        updateVersion={updateVersion}
        deleteVersion={deleteVersion}
        project={project}
        refetch={refetch}
      />
    </PageContainer>
  );
};

export default ProjectById;
