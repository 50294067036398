import { EUserRoles } from "utils/roles";

export const UserDataElements = (user?: IUser) => {
  const filteredRoles = Object.entries(EUserRoles).filter(([, value]) =>
    user!.roles.includes(value)
  );
  const rolesString: string = filteredRoles
    .map(([roleName]) => roleName)
    .join(" / ");

  const projectsNames = user!.myProjects?.join(" / ").trim() || "None";
  const teamsNames = "None";

  return [
    { title: "Username", element: user!.username },
    { title: "Password", element: "********" },
    { title: "Roles", element: rolesString },
    { title: "My Projects", element: projectsNames },
    { title: "My Teams", element: teamsNames },
  ];
};
