import { apiSlice } from "app/api/api.slice";
import { API_VERSION } from "utils/constants";

// const usersAdapter = createEntityAdapter();

// const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyUser: builder.query<IUser, void>({
      query: () => ({
        url: `/api/${API_VERSION}/users/me`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              { type: "User", id: result?._id },
              { type: "User", id: "USER_LIST" },
            ]
          : [{ type: "User", id: "USER_LIST" }],
    }),
    getUserById: builder.query<IUser, IUser["_id"]>({
      query: (userId) => ({
        url: `/api/${API_VERSION}/users/${userId}`,
        method: "GET",
      }),
      providesTags: (result, _error, id) =>
        result
          ? [
              { type: "User", id },
              { type: "User", id: "USER_LIST" },
            ]
          : [{ type: "User", id: "USER_LIST" }],
    }),
    getUsers: builder.query<IUser[], void>({
      query: () => ({
        url: `/api/${API_VERSION}/users/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({ type: "User" as const, id })),
              { type: "User", id: "USER_LIST" },
            ]
          : [{ type: "User", id: "USER_LIST" }],
    }),
    getUsersByTeamId: builder.query<IUser[], ITeam["_id"]>({
      query: (teamId) => ({
        url: `/api/${API_VERSION}/users/team/${teamId}`,
        method: "GET",
      }),
      providesTags: (result, _, teamId) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({ type: "User" as const, id })),
              { type: "User", id: "USER_LIST" },
              { type: "User", id: `USERS_TEAM_${teamId}_LIST` },
            ]
          : [
              { type: "User", id: "USER_LIST" },
              { type: "User", id: `USERS_TEAM_${teamId}_LIST` },
            ],
    }),
    updateAddUsersRole: builder.mutation<Partial<IUser>[], IUsersRoleUpdate>({
      query: (updates) => ({
        url: `/api/${API_VERSION}/users/roles`,
        method: "POST",
        body: updates,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({ type: "User" as const, id })),
              { type: "User", id: "USER_LIST" },
            ]
          : [{ type: "User", id: "USER_LIST" }],
    }),
    updateDeleteUsersRole: builder.mutation<Partial<IUser>[], IUsersRoleUpdate>(
      {
        query: (updates) => ({
          url: `/api/${API_VERSION}/users/roles`,
          method: "DELETE",
          body: updates,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                ...result.map(({ _id: id }) => ({ type: "User" as const, id })),
                { type: "User", id: "USER_LIST" },
              ]
            : [{ type: "User", id: "USER_LIST" }],
      }
    ),
    updateUserPassword: builder.mutation<
      Partial<IUser>,
      { password: string; oldPassword: string }
    >({
      query: (updates) => ({
        url: `/api/${API_VERSION}/users/password`,
        method: "PUT",
        body: updates,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetMyUserQuery,
  useGetUserByIdQuery,
  useGetUsersByTeamIdQuery,
  useUpdateAddUsersRoleMutation,
  useUpdateDeleteUsersRoleMutation,
  useUpdateUserPasswordMutation,
} = usersApiSlice;

// export const selectCurrentUser = (state: RootState) => state.auth.user;
