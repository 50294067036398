import { useGetTicketsQuery } from "features/tickets/ticketApi.slice";
import {
  priorityLevels,
  severityLevels,
  statusOptions,
  ticketTypes,
} from "utils/ticket/settings";

export const useTicketCounts = () => {
  const { data: tickets } = useGetTicketsQuery();

  const getCountPerIndex = (_: any, i: number) => ({ [i]: 0 });
  type INumericFields = Pick<
    ITicket,
    "priority" | "severity" | "status" | "type"
  >;
  const counts: Record<keyof INumericFields, { [k: number]: number }> = {
    priority: Object.assign({}, ...priorityLevels.map(getCountPerIndex)),
    status: Object.assign({}, ...statusOptions.map(getCountPerIndex)),
    severity: Object.assign({}, ...severityLevels.map(getCountPerIndex)),
    type: Object.assign({}, ...ticketTypes.map(getCountPerIndex)),
  };

  Object.keys(counts).forEach((key) => {
    const countIndicatorRef = counts[key as keyof INumericFields];
    const valuesByKey: number[] = !tickets
      ? []
      : tickets.map((ticket) => ticket[key as keyof INumericFields]);
    valuesByKey.forEach((val) => {
      countIndicatorRef[val] += 1;
    });
  });

  return counts;
};
