import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

type Props = {};

const MinLayout = (props: Props) => {
  return (
    <>
      <Outlet />
      <ToastContainer theme="colored" />
    </>
  );
};

export default MinLayout;
